import type { BattleGoalDeck } from './battle_goal_info.js';
import type { ConditionState } from './condition_info.js';
import {
  Condition,
  ConditionKind,
  condition_info,
  has_condition,
  is_dormant,
} from './condition_info.js';
import type { Enhancement, EnhancementV2 } from './enhancement_info.js';
import type { FsImage, FsImageLayout } from './fs_image.js';
import type { Hex } from './hex.js';
import type {
  AbilityCardInfo,
  BasicInfo,
  EventInfoV2,
  ItemSupplyKind,
  ModifierCard,
  MonsterDeckInfo,
  ScenarioInfoV2,
  SpecialRule,
} from './info.js';
import {
  AbilityCardLevel,
  CRIMSON_SCALES,
  FORGOTTEN_CIRCLES,
  FROSTHAVEN,
  InfoKind,
  JAWS_OF_THE_LION,
  MapItemKind,
  ModifierKind,
  is_character,
  map_item_kind_order,
} from './info.js';
import type { JournalV2 } from './journal.js';
import { JournalKind } from './journal.js';
import type { MapItem } from './map.js';
import type { BasicModifierDeck, ModifierDeck } from './modifiers.js';
import type { PlayLogUnion } from './play_log.js';
import type { PlayerState } from './player_state.js';
import type { Ability, Action } from './stats.js';
import { AbilityKind, AbilityFlag, is_persist } from './stats.js';
import type { AbilityV1 } from './stats_v1.js';
import type { HashMap, PlayerId } from './utility.js';

// The side of gloomhaven hex in Creater Pack map tiles have length ~112.6,
// based on the short diameter of 195.
// The size used in this app. Perhaps not the best place to define it.
export const SIZE = 112.58330298 / 3;

export type Vars = {
  schema_version: number;
  info_version: number;
};

export const MIN_CHARACTERS = 1;
export const MAX_CHARACTERS = 7;

// "Tag" types contain useful information that a user might want to see in
// order to choose the corresponding item. And some unique index to load it.

export type PlayerTag = {
  class_id: number;
  level: number;
  character_name?: string;
  player_id?: number;
  campaign_id?: number;
};

export type CampaignTag = {
  campaign_name: string;
  uuid: string;
  application_id: number;
  anon_uuid: string;
};

export interface Campaign {
  game_id: number;
  campaign_name: string;
  state: CampaignState;
  items: CampaignItem[];
  achievements: CampaignAchievement[];
  scenarios: CampaignScenario[];
  enhancements_v2: HashMap<EnhancementV2[]>; // index by class_id
  local_count?: number;
  journal_v2: JournalV2[];
  frosthaven: FhCampaignState;
  next_player_id: number;
  schema: number;
}

export interface CampaignCompat extends Campaign {
  enhancements: HashMap<EnhancementV2[]>;
}

export type CampaignState = {
  prosperity_checkmarks: number;
  reputation: number;
  donated_gold: number;
  personal_quest_ids: number[];
  unlocked_classes: string[];
  special_conditions: string[];
  completed_scenarios: number;
  guild_hall_rewards: number[];
  town_records_progress: number;
  start_group: string;
  looted_treasures: number[];
  hide_announcements?: string[];
  battle_goal_deck: BattleGoalDeck;
  enabled_games: number[];
  event_decks: HashMap<EventDeck>;
  unlocked_sections: number[];
  unlocked_sanctuary_modifiers: ModifierKind[];
  item_decks: HashMap<ItemDeck>;
  scenario_deck: BasicDeck;
};

export type Season = 'summer' | 'winter';

export interface FhCampaignState {
  resource_count: number[];
  morale: number;
  soldier_count: number;
  max_soldiers: number;
  inspiration: number;
  total_defense: number;
  season: Season;
  weeks_passed: number;
  calendar: CalendarEntry[];
  looted_item_scenarios: number[];
  buildings: BuildingState[];
  party_debt: FrosthavenCost;
  party_gain: FrosthavenCost;
  unhealthy_herbs: number[];
  town_guard_deck: BasicDeck;
  town_guard_perks: BasicPerks;
  loot_cards: LootCardState[];
  challenge_deck?: BasicDeck;
  trial_deck?: TrialDeck;
  pets?: PetState[];
  crops?: number[];
  carpenter?: number;
}

export interface TrialDeck extends BasicDeck {
  completed: number;
}

export interface PetState {
  pet_id: number;
  name: string;
}

export interface LootCardState {
  info_id: number;
  plus_ones: number;
}

export enum BuildingStatus {
  NORMAL = 0,
  DAMAGED = 1,
  WRECKED = 2,
}

export interface BuildingState {
  building_id: number;
  level: number;
  status: BuildingStatus;
  rotate?: boolean;
}

export interface CalendarEntry {
  sections: string[];
}

export interface FrosthavenCost {
  resources: number[];
  gold: number;
  prosperity?: number;
  morale?: number;
  inspiration?: number;
}

export function empty_fh_campaign_state(): FhCampaignState {
  return {
    resource_count: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    morale: 0,
    soldier_count: 0,
    max_soldiers: 0,
    inspiration: 0,
    total_defense: 0,
    season: 'summer',
    weeks_passed: 0,
    calendar: [
      { sections: [] }, // summer
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: ['32.3'] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: ['183.3', '21.4'] },
      { sections: [] }, // winter
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: ['129.3'] },
      { sections: [] }, // summer
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: ['183.3'] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: ['183.3'] },
      { sections: [] }, // winter
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: ['184.1'] },
      { sections: [] }, // summer
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] }, // winter
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] }, // summer
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] }, // winter
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: [] },
      { sections: ['137.2'] },
    ],
    looted_item_scenarios: [],
    buildings: [],
    party_debt: zero_fh_cost(),
    party_gain: zero_fh_cost(),
    unhealthy_herbs: [],
    town_guard_deck: null,
    town_guard_perks: null,
    loot_cards: [],
  };
}

export function zero_fh_cost(): FrosthavenCost {
  return {
    resources: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    gold: 0,
  };
}

export const start_groups: HashMap<string[]> = {
  Militants: ['Militants', 'bombard', 'fire-knight', 'hierophant', 'mirefoot'],
  Protectors: ['Protectors', 'chainguard', 'chieftain', 'fire-knight', 'hierophant'],
  Explorers: ['Explorers', 'brightspark', 'chainguard', 'hollowpact', 'starslinger'],
  Trailblazers: ['Trailblazers', 'bombard', 'brightspark', 'luminary', 'starslinger'],
  Naturalists: ['Naturalists', 'chieftain', 'hollowpact', 'luminary', 'mirefoot'],
};

export type CampaignAchievement = {
  info_id: number;
  object_code: string;
  count: number;
  kind?: string;
};

export type CampaignItem = {
  item_info_id: number;
  market_count: number;
  max_market_count: number;
  reward_count: number; // received from events, treasures, rewards
  design_unlocked: boolean;
};

export type CampaignScenario = {
  scenario_id: number;
  scenario_status: string; // locked, unlocked, failed, completed, missing, impossible, solo, random
  completed_journal_id: number;
  looted_random_item?: boolean;
  revealed?: string[];
};

export type EventDeck = {
  info_kind: InfoKind;
  deck: number[];
  locked: number[];
  removed: number[];
};

export type EventOutcome = {
  info_kind: InfoKind;
  event_id: number;
  outcome: string; // A or B
  choices: number[];
  info?: EventInfoV2;
};

export enum MapConfig {
  DEFAULT,
  NO_MAP,
  TWO_CHAR,
  THREE_CHAR,
  FOUR_CHAR,
  FIVE_CHAR,
  SIX_CHAR,
  SEVEN_CHAR,
  MAX = 7,
}

export const map_config_names = [
  'Default',
  'No map',
  'Two char.',
  'Three char.',
  'Four char.',
  '2+3',
  '2+4',
  '3+4',
];

export interface MapConfigInfo {
  map_config: MapConfig;
  name: string;
  description: string;
}

export const map_config_info: MapConfigInfo[] = [
  {
    map_config: MapConfig.DEFAULT,
    name: 'Default',
    description: 'Add monsters for number of characters in party',
  },
  {
    map_config: MapConfig.NO_MAP,
    name: 'No map',
    description: 'Add all monsters manually',
  },
  {
    map_config: MapConfig.TWO_CHAR,
    name: 'Two char.',
    description: 'Add monsters for two characters',
  },
  {
    map_config: MapConfig.THREE_CHAR,
    name: 'Three char.',
    description: 'Add monsters for three characters',
  },
  {
    map_config: MapConfig.FOUR_CHAR,
    name: 'Four char.',
    description: 'Add monsters for four characters',
  },
  {
    map_config: MapConfig.FIVE_CHAR,
    name: '2 + 3',
    description: 'Add monsters for both two and three characters',
  },
  {
    map_config: MapConfig.SIX_CHAR,
    name: '2 + 4',
    description: 'Add monsters for both two and four characters',
  },
  {
    map_config: MapConfig.SEVEN_CHAR,
    name: '3 + 4',
    description: 'Add monsters for both three and four characters',
  },
];

export type Settings = {
  casual: boolean;
  solo_variant: number; // 0 - none, 1 - GH, 2 - FH
  difficulty: Difficulty;
  normal: number;
  scenario_level: number;
  monster_level: number;
  reveal_mode: RevealMode;
  summons_drop_coins: boolean;
  map_config: MapConfig;
  player_count: number;
  player_ids: number[];
  donated_ids: number[];
  player_goals: HashMap<CharacterBattleGoal>;
  auto_item: boolean;
  auto_map: boolean;
  auto_condition: boolean;
  auto_ability: boolean;
  enhance_game_id: number;
  unlimited_market: boolean;
  pool_resources: boolean;
};

export type PlayerCharacter = {
  player_id: number;
  class_id: number;
  character_name: string;
  level: number;
  xp: number;
  gold: number;
  player_status: string;
  quest: CharacterQuest;
  perks: CharacterPerks;
  items: CharacterItems;
  ability_cards: CharacterAbilityCards;
  modifier_deck: ModifierCard[];
  lineage: number[]; // player_ids
  notes: string;
  player_state: PlayerState;
  milestone: CharacterMilestone;
  frosthaven: FhPlayerState;
};

export interface FhPlayerState {
  resource_count: number[];
  trial_id: number;
}

export type EnhancementCost = {
  base_cost: number;
  level_cost: number;
  previous_cost: number;
  total_cost: number;
};

export type CharacterMilestone = {
  checkmarks: number;
  unlocked: boolean;
};

export type CharacterQuest = {
  quest_id: number;
  choosing_ids: number[];
  progress: string;
  inspiration_quest_id?: number;
};

export type CharacterBattleGoal = {
  goal_id: number;
  choosing_ids: number[];
  completed: number; // 0 - in progress, 1 - completed, 2 - failed
};

export interface BasicPerks {
  bonus_perks: number;
  checkmark_count: number;
  selected_perks: number[];
  added_cards?: number[];
  removed_cards?: number[];
}

export interface CharacterPerks extends BasicPerks {
  completed_masteries: number[];
  use_crossover: boolean;
}

export type CharacterItems = {
  purchased: number[];
};

export interface CharacterAbilityCards {
  available_pool_ids: number[];
  in_hand_ids: number[];
}

export interface CharacterAbilityCardsCompat extends CharacterAbilityCards {
  available_pool: String[];
  in_hand: String[];
}

export type PlayingState = {
  game_id: number;
  scenario_id: number;
  play_state: PlayState;
  campaign_state: CampaignState;
  round_state: RoundState;
  map_state: MapState;
  figure_groups: HashMap<FigureGroup>;
  figures: HashMap<FigureState>;
  group_order: number[];
  attack_modifiers: AttackModifiers;
  settings: Settings;
  player_states: PlayerState[];
  special_rules: SpecialRule[];
  condition_ids: number[];
  create_time: Date;
  start_time: Date;
  end_time: Date;
  play_log: PlayLogUnion[];
  local_count?: number;
  scenario_info?: ScenarioInfoV2;
  frosthaven: FhPlayingState;
};

export interface BasicDeck {
  cards: number[];
  index: number;
}

export interface LootDeck {
  cards: number[];
  index: number;
}

export interface ItemDeck extends BasicDeck {
  item_supply_kind: ItemSupplyKind;
}

export interface FhPlayingState {
  loot_deck?: LootDeck;
  pet_ids?: number[];
  imitation_class_id?: number;
  favors?: Favors;
}

export interface Favors {
  minus_ones: number;
  minus_twos: number;
  favor_counts: number[];
  strategy?: HashMap<FavorStrategy>;
}

// Each deck can only be updated once.
export interface FavorStrategy {
  monster_deck_id: number;
  random_cards: number[];
  removed_card: number;
}

export type MapState = {
  revealed_tiles: string[]; // includes section labels
  tiles: MapItem[];
  doors: MapItem[];
  start: MapItem[];
  overlays: MapItem[];
  tokens: MapItem[];
  monsters: MapItem[];
  room_card_ids: number[];
  monster_card_ids: number[];
};

export enum PlayState {
  CARD_SELECTION = 0,
  DETERMINE_INITIATIVE = 1,
  FIGURE_TURN = 2,
  CLEANUP = 3,
  COMPLETED = 4,
  DELETED = 5,
  NEW = 6,
}

export type FigureId = {
  group_index: number;
  figure_index: number;
};

export enum ElementState {
  INERT = 0,
  WANING = 1,
  STRONG = 2,
  INFUSING = 4,
  CONSUMED = 8,
  AVAILABLE = WANING | STRONG,
  CHANGING = INFUSING | CONSUMED,
}

export enum RevealMode {
  NONE = 0,
  AUTOMATIC = 1,
  MANUAL = 2,
}

export enum Difficulty {
  NONE = -10,
  EASY = -1,
  NORMAL = 0,
  HARD = 1,
  VERY_HARD = 2,
}

export enum ScenarioSectionStatus {
  HIDDEN = 0,
  VISIBLE = 1,
  REVEALED = 2,
  LOCKED = 3,
}

export interface ScenarioSectionStatusInfo {
  section_status: ScenarioSectionStatus;
  name: string;
}

export const scenario_section_status_info: ScenarioSectionStatusInfo[] = [
  { section_status: ScenarioSectionStatus.HIDDEN, name: 'Hidden' },
  { section_status: ScenarioSectionStatus.VISIBLE, name: 'Visible' },
  { section_status: ScenarioSectionStatus.REVEALED, name: 'Revealed' },
  { section_status: ScenarioSectionStatus.LOCKED, name: 'Locked' },
];

export type RoundState = {
  current_figure_id: number;
  round: number;
  elements: HashMap<number>;
  unlooted_coins: number;
  success: boolean;
  next_id: number;
  reward_choices: number[];
  disable_special_rules: boolean;
  ability_decks: HashMap<AbilityDeck>;
  cycle_index: number; // used by GH Prime Demon only
  next_cycle_index: number;
  values: HashMap<any>;
  event_outcomes: HashMap<EventOutcome>;
  section_status: ScenarioSectionStatus[];
  advance_id: string;
  advance_timestamp: number;
};

export enum AbilityDeckDrawKind {
  DEFAULT = 0,
  DRAW_2 = 1, // The group draws 2 and performs both
  SEPARATE = 2, // Each group draws a separate card
  CHOOSE = 3, // No automatic draws, the user chooses a card
}

export interface AbilityDeckDrawKindInfo {
  kind: AbilityDeckDrawKind;
  name: string;
  description: string;
}

export const ability_deck_draw_kind_info: AbilityDeckDrawKindInfo[] = [
  {
    kind: AbilityDeckDrawKind.DEFAULT,
    name: 'Normal',
    description: 'Draw cards according to normal rules.',
  },
  {
    kind: AbilityDeckDrawKind.DRAW_2,
    name: 'Draw 2',
    description: 'Draw 2 cards and perform both according to their initiatives.',
  },
  {
    kind: AbilityDeckDrawKind.SEPARATE,
    name: 'Separate',
    description: 'Draw a separate card for every group using this deck.',
  },
  {
    kind: AbilityDeckDrawKind.CHOOSE,
    name: 'Choose',
    description: 'Choose a specific card and never shuffle.',
  },
];

export interface AbilityDeck extends BasicDeck {
  monster_deck_id: number;
  shuffle: boolean;
  round_drawn: number;
  peek_index: number;
  draw_kind: AbilityDeckDrawKind;
  removed?: number[];
}

export type FigureGroup = {
  name: string;
  object_code: string;
  info_id: number;
  info_kind: InfoKind;
  monster_deck_id: number;
  group_id: number;
  image_info_id?: number; // for multi-hex objectives
  standee_count: number;
  free_standees: string[];
  free_standees_elite?: string[];
  initiative: number;
  second_init: number;
  socket_id: string;
  base_health: number[];
  base_abilities_v2: Ability[];
  abilities_v2: Ability[]; // the combined abilities
  level: number;
  figure_status: FigureStatus;
  figure_order: number[];
  is_dead: boolean;
  is_dormant?: boolean;
  summoner_code?: string;
  summon_color?: string;
  player_id?: number;
  order?: number;
  xp?: number;
  level_xp?: number;
  loot?: number;
  gold?: number;
  notes?: string;
  ability_card_index: number; // index into MonsterDeckInfo.cards
  modifier_code: string;
  title?: string;
  summons?: any[];
  loot_card_ids?: number[];
  long_rest?: boolean;
  portrait_layout?: FsImageLayout;
  stat_level?: number;
  flying?: boolean;
};

export interface FigureGroupCompat extends FigureGroup {
  abilities: AbilityV1[];
  base_abilities: AbilityV1[];
}

export type FigureInit = {
  info_id: number;
  variant: string;
  q: number;
  r: number;
  hex_rotate?: number;
  summoner_code?: string;
  misc?: string;
  health?: number;
};

export interface PrevHex extends Hex {
  id: number;
}

export type FigureState = {
  id: number;
  change_id: number; // trigger render in UI
  object_code: string;
  modifier_code: string;
  info_id: number;
  image_info_id: number; // for figures that take multiple hexes.
  variant: string;
  health: number;
  max_health: number;
  standee_number: string;
  standee_color: string;
  standee_order?: string;
  conditions: HashMap<ConditionState>;
  q: number;
  r: number;
  hex_rotate?: number; // for multi-hex objectives
  prev_hexes: PrevHex[];
  figure_status: FigureStatus;
  auto_status: AutoStatus;
  order?: number;
  ability_states?: AbilityState[];
  misc?: string;
};

export enum FigureStatus {
  NONE = 0,
  START_OF_ROUND = 1,
  GROUP_TURN_PENDING = 2,
  FIGURE_TURN_PENDING = 3,
  CURRENT_FIGURE = 4,
  CURRENT_FIGURE_DISABLE_AUTO = 5, // deprecated
  FIGURE_TURN_COMPLETED = 6,
  GROUP_TURN_COMPLETED = 7,
}

export enum AutoStatus {
  NONE = 0,
  CONDITIONS_RESOLVED = 1,
  ABILITIES_PERFORMED = 2,
  LONG_REST_HEALED = 4,
  CONDITIONS_RESOLVED_END = 8,
  ALL = 15,
}

export type AbilityState = {
  ability_kind: AbilityFlag; // One of the PERSISTENT sub kinds
  persistent: number;
  end_of_round: number;
  user_persistent: number;
  user_end_of_round: number;
};

export type FigureStats = {
  level: number;
  health: number;
  base: Action[];
  bonus?: Action[];
  misc_effect?: string[];
  immune_condition?: string[];
  special1?: string;
  special2?: string;
  notes?: string;
  variant?: string;
};

export type AttackModifiers = {
  minus_ones: ModifierCard[];
  blesses: ModifierCard[];
  player_curses: ModifierCard[];
  monster_curses: ModifierCard[];
  oaks_gift_rolling?: ModifierCard[];
  oaks_gift_2x?: ModifierCard[];
  empower?: HashMap<ModifierCard[]>;
  bonus_decks: HashMap<ModifierCard[]>;
  decks: HashMap<ModifierDeck>;
};

export type RoundInfo = {
  level: number;
  gold: number; // per loot token
  xp: number; // bonus for successfully completing scenario
  trap: number; // damage for damage trap
  hazard: number;
};

export type ErrorMessage = {
  server_event: string;
  error_message: string;
};

export const summon_colors = [
  'summon_red',
  'summon_orange',
  'summon_yellow',
  'summon_green',
  'summon_blue',
  'summon_purple',
  'summon_violet',
  'summon_white',
];

export function xp_to_level(xp: number): number {
  var level_xp = 45;
  var level = 1;
  while (level_xp <= xp && level < 9) {
    xp -= level_xp;
    level++;
    level_xp += 5;
  }
  return level;
}

export function level_to_xp(level: number): number {
  var level_xp = 45;
  var xp = 0;
  for (let l = 1; l < level; l++) {
    xp += level_xp;
    level_xp += 5;
  }
  return xp;
}

export function level_to_start_gold(
  game_id: number,
  level: number,
  plevel: number
): number {
  if (game_id === JAWS_OF_THE_LION.game_id && level === 1) return 0;
  if (game_id === FROSTHAVEN.game_id) return plevel * 10 + 20;
  return (level + 1) * 15;
}

export interface HasFigures {
  figures: HashMap<FigureState>;
}

export interface HasGroups {
  figure_groups: HashMap<FigureGroup>;
}

export interface HasGroupsAndOrder {
  figure_groups: HashMap<FigureGroup>;
  group_order: number[];
}

export interface HasFiguresAndGroups {
  figure_groups: HashMap<FigureGroup>;
  figures: HashMap<FigureState>;
}

export interface HasFiguresGroupsAndPlayState {
  figure_groups: HashMap<FigureGroup>;
  figures: HashMap<FigureState>;
}

export function get_figure(playing_state: HasFigures, id: number): FigureState {
  return playing_state && id > 0 ? playing_state.figures[id] : null;
}

export function get_figure_group(playing_state: HasGroups, info_id: number) {
  return playing_state && info_id ? playing_state.figure_groups[info_id] : null;
}

export function find_figure_group(playing_state: HasGroups, code: string) {
  if (!playing_state) return null;
  const info_id = parseInt(code);
  if (!isNaN(info_id)) return playing_state.figure_groups[info_id];
  return Object.values(playing_state.figure_groups).find((g) => g.object_code === code);
}

export function get_figure_group_for_id(
  playing_state: HasFiguresAndGroups,
  id: number
): FigureGroup {
  if (!playing_state) return null;
  const figure = playing_state.figures[id];
  return figure ? playing_state.figure_groups[figure.info_id] : null;
}

export function get_current_figure_group(playing_state: PlayingState): FigureGroup {
  if (!playing_state) return null;
  if (!is_turn_in_progress(playing_state)) return null;
  return get_groups(playing_state).find(is_current_group);
}

export function get_current_figure(playing_state: PlayingState): FigureState {
  if (!playing_state) return null;
  if (!is_turn_in_progress(playing_state)) return null;
  return playing_state.figures[playing_state.round_state.current_figure_id];
}

export function is_current_figure(figure: FigureState) {
  return (
    figure.figure_status === FigureStatus.CURRENT_FIGURE ||
    figure.figure_status === FigureStatus.CURRENT_FIGURE_DISABLE_AUTO
  );
}

export function is_available(state: ElementState) {
  return (state & ElementState.AVAILABLE) != 0;
}

export function is_waning(state: ElementState) {
  return (state & ElementState.WANING) != 0;
}

export function is_infusing(state: ElementState) {
  return (state & ElementState.INFUSING) != 0;
}

export function is_consumed(state: ElementState) {
  return (state & ElementState.CONSUMED) != 0;
}

export function is_manual_standees(playing_state: PlayingState): boolean {
  return playing_state.settings.reveal_mode == RevealMode.MANUAL;
}

interface HasPlayState {
  play_state: PlayState;
}

export function is_setting_initiative(has_state: HasPlayState): boolean {
  return (
    has_state.play_state === PlayState.CARD_SELECTION ||
    has_state.play_state === PlayState.DETERMINE_INITIATIVE ||
    has_state.play_state === PlayState.NEW
  );
}

export function is_turn_in_progress(playing_state: HasPlayState): boolean {
  return playing_state.play_state == PlayState.FIGURE_TURN;
}

export function is_completed(playing_state: HasPlayState): boolean {
  return playing_state?.play_state === PlayState.COMPLETED;
}

export function is_start_of_scenario(playing_state: PlayingState): boolean {
  return (
    playing_state.play_state === PlayState.COMPLETED ||
    (playing_state.round_state.round <= 1 && is_setting_initiative(playing_state))
  );
}

export interface HasFigureStatus {
  figure_status: FigureStatus;
}

export function is_turn_completed(figure: HasFigureStatus) {
  switch (figure.figure_status) {
    case FigureStatus.NONE:
    case FigureStatus.START_OF_ROUND:
    case FigureStatus.GROUP_TURN_PENDING:
    case FigureStatus.FIGURE_TURN_PENDING:
    case FigureStatus.CURRENT_FIGURE:
    case FigureStatus.CURRENT_FIGURE_DISABLE_AUTO:
      return false;
    case FigureStatus.FIGURE_TURN_COMPLETED:
    case FigureStatus.GROUP_TURN_COMPLETED:
      return true;
  }
  return false;
}

export function is_current_group(figure: HasFigureStatus) {
  if (!figure) return false;
  switch (figure.figure_status) {
    case FigureStatus.NONE:
    case FigureStatus.START_OF_ROUND:
    case FigureStatus.GROUP_TURN_PENDING:
    case FigureStatus.GROUP_TURN_COMPLETED:
      return false;
    case FigureStatus.FIGURE_TURN_PENDING:
    case FigureStatus.CURRENT_FIGURE:
    case FigureStatus.CURRENT_FIGURE_DISABLE_AUTO:
    case FigureStatus.FIGURE_TURN_COMPLETED:
      return true;
  }
  return false;
}

export function is_group_completed(group: FigureGroup) {
  return group?.figure_status === FigureStatus.GROUP_TURN_COMPLETED;
}

export function is_mob_variant(figure: FigureState) {
  return (
    figure.variant === 'normal' || figure.variant === 'elite' || figure.variant === 'boss'
  );
}

export function is_elite(figure: FigureState) {
  return figure.variant === 'elite';
}

export function is_normal(figure: FigureState) {
  return figure.variant === 'normal';
}

export function compare_strings(str1: string, str2: string) {
  return str1?.localeCompare(str2) ?? -1;
}

const kind_order = [
  0, // NONE,
  1, // GAME,
  2, // CLASS,
  5, // MONSTER,
  4, // BOSS,
  3, // SUMMON,
  6, // OBJECTIVE,
  7, // MONSTER_DECK,
  8, // ITEM,
  9, // SCENARIO,
  10, // CITY_EVENT,
  11, // ROAD_EVENT,
  12, // QUEST,
  13, // GLOOMHAVEN,
  14, // SOLO,
  15, // COMMUNITY,
  16, // RANDOM_DUNGEON,
  17, // RANDOM_ROOM,
  18, // RANDOM_MONSTER,
  19, // NAMED_MONSTER,
  20, // RIFT_EVENT,
];

export function compare_group_kind(f1: FigureGroup, f2: FigureGroup) {
  const dead1 = is_dead_group(f1) ? 1 : 0;
  const dead2 = is_dead_group(f2) ? 1 : 0;
  if (dead1 !== dead2) return dead1 - dead2;
  if (f1.info_kind !== f2.info_kind)
    return kind_order[f1.info_kind] - kind_order[f2.info_kind];
  const p1 = f1.player_id ?? 0;
  const p2 = f2.player_id ?? 0;
  if (p1 !== p2) return p2 - p1;
  // Make sure summons are in the order they were summoned.
  return f1.group_id - f2.group_id;
}

export function is_dead_group(group: FigureGroup) {
  return group.is_dead;
}

export function is_dormant_group(playing_state: PlayingState, group: FigureGroup) {
  if (!group) return false;
  if (group.is_dormant) return true;
  return get_figures(playing_state, group).every((f) => is_dormant(f.conditions));
}

export function is_dormant_figure(figure: FigureState) {
  if (!figure) return false;
  return is_dormant(figure.conditions);
}

export function compare_standees(str1: string, str2: string) {
  if (!str1 && !str2) return 0;
  if (!str1) return 1;
  if (!str2) return -1;
  if (str1?.length !== str2?.length) return (str1?.length ?? 0) - (str2?.length ?? 0);
  return compare_strings(str1, str2);
}

export function compare_figures(f1: FigureState, f2: FigureState) {
  if (f1.variant != f2.variant) {
    if (f1.variant === 'elite') return -1;
    if (f2.variant === 'elite') return 1;
    if (f1.variant === 'character') return 1;
    if (f2.variant === 'character') return -1;
  }
  if (f1.variant.startsWith('summon') && f2.variant.startsWith('summon')) {
    // id is always increasing, so lower id was summoned first and should take
    // its turn first.
    return f1.id - f2.id;
  }
  const cmp = compare_standees(f1.standee_number, f2.standee_number);
  if (cmp) return cmp;
  return f1.id - f2.id;
}

export function is_revealing_room(playing_state: PlayingState) {
  if (playing_state.round_state.round == 1 && is_setting_initiative(playing_state))
    return true;
  const group = get_current_figure_group(playing_state);
  return group && group.info_kind === InfoKind.CLASS;
}

export function is_summon_variant(figure: FigureState): boolean {
  return figure && figure.variant === 'summon';
}

export function is_character_variant(figure: FigureState): boolean {
  return figure && figure.variant === 'character';
}

export function is_objective_variant(figure: FigureState): boolean {
  return figure && figure.variant === 'objective';
}

export function is_enemy_variant(figure: FigureState): boolean {
  // We say by definition that objectives are not enemies, because
  // they are usually objects, not figures.
  return (
    figure &&
    (figure.variant === 'normal' ||
      figure.variant === 'elite' ||
      figure.variant === 'boss')
  );
}

export function is_ally_variant(figure: FigureState): boolean {
  // TODO: special allies and objectives
  return (
    figure &&
    (figure.variant === 'character' ||
      figure.variant === 'summon' ||
      has_condition(figure.conditions, Condition.ALLY))
  );
}

export function is_tile_revealed(playing_state: PlayingState, tile: string) {
  return tile === 'any' || playing_state.map_state.revealed_tiles.includes(tile);
}

function _get_variant(
  playing_state: PlayingState,
  summon: string[],
  figure?: FigureState
) {
  const count = character_count(playing_state);
  const variant = summon[Math.max(2, Math.min(count, 4))];
  if (variant === 'alternate')
    return playing_state.round_state.values[summon[0]] ?? 'normal';
  if (variant === 'matching') return figure?.variant ?? 'normal';
  return variant;
}

export function ability_variant(
  playing_state: PlayingState,
  ability: Ability,
  figure: FigureState
) {
  const action = ability?.actions?.[0];
  if (action?.length <= 4) return 'error';
  return _get_variant(playing_state, action, figure);
}

export function action_variant(
  playing_state: PlayingState,
  action: Action,
  figure?: FigureState
) {
  if (!action || action.length <= 5) return 'error';
  return _get_variant(playing_state, action.slice(1), figure);
}

export function is_even_round(playing_state: PlayingState) {
  return (playing_state.round_state.round & 1) == 0;
}

export function is_odd_round(playing_state: PlayingState) {
  return (playing_state.round_state.round & 1) == 1;
}

export function reputation_to_discount(reputation: number) {
  return -1 * Math.trunc((reputation + Math.sign(reputation)) / 4);
}

export function market_discount(state: CampaignState) {
  return reputation_to_discount(state.reputation);
}

export function settings_round_info(playing_state: PlayingState) {
  return round_info(
    playing_state.settings.scenario_level,
    playing_state.settings.solo_variant === 1 ? 1 : 0,
    playing_state.game_id
  );
}

export function round_info(
  level: number,
  trap_delta: number,
  game_id: number
): RoundInfo {
  level = Math.max(0, Math.min(level || 0, 7));
  const trap = level + 2 + trap_delta;
  return {
    level,
    gold: [2, 2, 3, 3, 4, 4, 5, 6][level],
    trap,
    hazard: game_id === 5 ? 1 + Math.ceil(level / 3) : Math.floor(trap / 2),
    xp: (level + 2) * 2,
  };
}

export function retired_count(players: PlayerCharacter[]) {
  if (!players) return 0;
  return players.filter(is_player_retired).length;
}

export function is_scenario_completed(campaign: Campaign, scenario_id: number) {
  const scenario = campaign?.scenarios.find((s) => s.scenario_id === scenario_id);
  return scenario?.scenario_status === 'completed';
}

export function is_scenario_locked(campaign: Campaign, scenario_id: number) {
  const scenario = campaign?.scenarios.find((s) => s.scenario_id === scenario_id);
  return !scenario || scenario.scenario_status === 'locked';
}

export function is_player_deleted(player: PlayerCharacter) {
  return player.player_status === 'deleted';
}

export function is_player_retired(player: PlayerCharacter) {
  return player.player_status === 'retired' || player.player_status === 'retiring';
}

export function is_player_active(player: PlayerCharacter) {
  return player.player_status === 'active';
}

export function playing_players(
  players: PlayerCharacter[],
  settings: Settings
): PlayerCharacter[] {
  if (!players || !settings || !settings.player_ids) return [];
  return players.filter(
    (p) => is_player_active(p) && settings.player_ids.includes(p.player_id)
  );
}

export function find_player<Type extends PlayerId>(
  players: Type[],
  player_id: number
): Type {
  if (!players || !player_id) return null;
  return players.find((p) => p.player_id === player_id);
}

export function valid_settings(settings: Settings) {
  if (!settings) return false;
  return (
    settings.normal + settings.difficulty >= 0 &&
    settings.normal + settings.difficulty <= 7 &&
    (settings.reveal_mode === RevealMode.MANUAL ||
      settings.reveal_mode === RevealMode.AUTOMATIC) &&
    settings.player_ids &&
    settings.player_ids.length >= MIN_CHARACTERS &&
    settings.player_ids.length <= MAX_CHARACTERS
  );
}

export function set_add<Type>(arr: Array<Type>, elem: Type) {
  if (arr.includes(elem)) return false;
  arr.push(elem);
  return true;
}

export function set_toggle<Type>(arr: Array<Type>, elem: Type) {
  if (set_has(arr, elem)) return set_rem(arr, elem);
  return set_add(arr, elem);
}

export function set_union<Type>(arr: Array<Type>, elems: Array<Type>) {
  var changed = false;
  for (let e of elems) changed = set_add(arr, e) || changed;
  return changed;
}

export function set_rem<Type>(arr: Array<Type>, elem: Type) {
  const index = arr.findIndex((e) => e === elem);
  if (index < 0) return false;
  arr.splice(index, 1);
  return true;
}

export function set_sub<Type>(arr: Array<Type>, elems: Array<Type>) {
  elems.forEach((e) => set_rem(arr, e));
}

export function set_intersect<Type>(arr: Array<Type>, elems: Array<Type>) {
  const sub = arr.filter((a) => !set_has(elems, a));
  set_sub(arr, sub);
}

export function set_has<Type>(arr: Array<Type>, elem: Type) {
  return arr && arr.includes(elem);
}

export function get_player(players: PlayerCharacter[], id: number) {
  if (!players || !id) return null;
  return players.find((p) => p.player_id === id);
}

export function character_count(playing_state: PlayingState) {
  return playing_state.settings.player_count;
}

export function difficulty_label(difficulty: Difficulty) {
  switch (difficulty) {
    case Difficulty.EASY:
      return 'Easy';
    case Difficulty.NORMAL:
      return 'Normal';
    case Difficulty.HARD:
      return 'Hard';
    case Difficulty.VERY_HARD:
      return 'Very hard';
  }
  return 'Unknown';
}

export function get_groups(playing_state: HasGroups): FigureGroup[] {
  if (!playing_state || !playing_state.figure_groups) return [];
  return Object.values(playing_state.figure_groups);
}

export function get_group(playing_state: HasGroups, class_id: number): FigureGroup {
  return playing_state?.figure_groups?.[class_id];
}

export function get_groups_ordered(playing_state: HasGroupsAndOrder): FigureGroup[] {
  if (!playing_state || !playing_state.figure_groups) return [];
  return playing_state.group_order.map((o) => playing_state.figure_groups[o]);
}

export function get_figures(playing_state: HasFiguresAndGroups): FigureState[];
export function get_figures(
  playing_state: HasFiguresAndGroups,
  param: number
): FigureState[];
export function get_figures(
  playing_state: HasFiguresAndGroups,
  param: FigureGroup
): FigureState[];

export function get_figures(
  playing_state: HasFiguresAndGroups,
  param?: any
): FigureState[] {
  if (!playing_state || !playing_state.figures) return [];
  if (!param) return Object.values(playing_state.figures);
  const group: FigureGroup =
    typeof param === 'number' ? playing_state.figure_groups[param] : param;
  if (group?.figure_order) return group.figure_order.map((o) => playing_state.figures[o]);
  if (group?.info_id)
    return Object.values(playing_state.figures).filter(
      (f) => f.info_id === group.info_id
    );
  return [];
}

export function get_character(playing_state: PlayingState, param: number): FigureState;
export function get_character(
  playing_state: PlayingState,
  param: FigureGroup
): FigureState;
export function get_character(playing_state: PlayingState, param: any): FigureState {
  const figures = get_figures(playing_state, param);
  if (!figures) return null;
  return figures.find((f) => f.variant === 'character');
}

export function has_achievement(campaign: Campaign, object_code: string) {
  return campaign.achievements.some((a) => a.object_code === object_code);
}

export function is_random_scenario(scenario_id: number) {
  return scenario_id === 1092 || scenario_id === 1147;
}

export function is_purchased(player: PlayerCharacter, item: CampaignItem) {
  return player?.items.purchased.some((n) => n === item.item_info_id);
}

export function in_available_pool(player: PlayerCharacter, card: AbilityCardInfo) {
  if (!player || (card.level >= 0 && card.level <= 1)) return true;
  if (player.milestone.unlocked && card.level === AbilityCardLevel.MILESTONE) return true;
  if (!player.ability_cards) return false;
  return player.ability_cards.available_pool_ids?.includes(card.info_id);
}

export function is_level_up_card(player: PlayerCharacter, card: AbilityCardInfo) {
  if (!player || card.level <= 1) return false;
  if (player.ability_cards?.available_pool_ids?.includes(card.info_id)) return false;
  const chosen = !player.ability_cards?.available_pool_ids
    ? 0
    : player.ability_cards.available_pool_ids.length;
  if (card.initiative === 0 && card.level <= chosen + 1) return false;
  return card.level <= chosen + 2;
}

export function is_a_or_b_card(player: PlayerCharacter, card: AbilityCardInfo) {
  if (!player) return false;
  return card.level === AbilityCardLevel.A || card.level === AbilityCardLevel.B;
}

export function is_m_card(player: PlayerCharacter, card: AbilityCardInfo) {
  if (!player) return false;
  return card.level === AbilityCardLevel.M;
}

export function is_p_card(player: PlayerCharacter, card: AbilityCardInfo) {
  if (!player) return false;
  return card.level === AbilityCardLevel.P;
}

export function is_v_card(player: PlayerCharacter, card: AbilityCardInfo) {
  if (!player) return false;
  return card.level === AbilityCardLevel.V;
}

export function is_supply_card(card: AbilityCardInfo) {
  return (
    card.level === AbilityCardLevel.P ||
    card.level === AbilityCardLevel.M ||
    card.level === AbilityCardLevel.V
  );
}

export function reveal_mode_name(mode: RevealMode) {
  switch (mode) {
    case RevealMode.NONE:
      return 'None';
    case RevealMode.MANUAL:
      return 'Manual';
    case RevealMode.AUTOMATIC:
      return 'Automatic';
  }
  return `Unknown ${mode}`;
}

export function ability_deck_for_id(playing_state: PlayingState, id: number) {
  return playing_state.round_state?.ability_decks[id];
}
export function ability_deck(playing_state: PlayingState, group: FigureGroup) {
  return ability_deck_for_id(playing_state, group.monster_deck_id);
}

export function is_rot90(start: MapItem[]) {
  return start?.[0]?.info_id === 2555;
}

export function is_treasure_looted(campaign: Campaign, info_id: number) {
  return set_has(campaign.state.looted_treasures, info_id);
}

export function free_standees(group: FigureGroup, variant: string) {
  if (!group.free_standees_elite || variant === 'normal') return group.free_standees;
  return group.free_standees_elite;
}

export function enabled_games(campaign: Campaign) {
  return campaign.state.enabled_games ?? [campaign.game_id];
}

export interface HasGameId {
  game_id: number;
}

export function is_game_enabled(campaign: Campaign, obj: HasGameId) {
  return (
    campaign.game_id === obj.game_id ||
    campaign.state.enabled_games?.includes(obj.game_id)
  );
}

export function is_game(g1: HasGameId, g2: HasGameId) {
  return g1.game_id === g2.game_id;
}

export function is_frosthaven(g1: HasGameId) {
  return is_game(FROSTHAVEN, g1);
}

export function event_journal_kind(info_kind: InfoKind) {
  switch (info_kind) {
    case InfoKind.ROAD_EVENT:
    case InfoKind.RIFT_EVENT:
      return JournalKind.TRAVEL;
    default:
    case InfoKind.CITY_EVENT:
      break;
  }
  return JournalKind.GLOOMHAVEN;
}

export function init_bonuses(
  code: string,
  campaign: Campaign,
  modifiers?: AttackModifiers
) {
  const bonuses: ModifierKind[] = [ModifierKind.BLESS];
  bonuses.push(
    code === 'monster' ? ModifierKind.MONSTER_CURSE : ModifierKind.PLAYER_CURSE
  );
  bonuses.push(ModifierKind.MINUS_ONE);
  if (is_game_enabled(campaign, FROSTHAVEN) || is_game_enabled(campaign, CRIMSON_SCALES))
    bonuses.push(ModifierKind.MINUS_TWO);
  if (is_game_enabled(campaign, FROSTHAVEN)) bonuses.push(ModifierKind.PLUS_ZERO);
  if (code === 'monster') {
    if (modifiers && bonus_deck(modifiers, ModifierKind.ENFEEBLE_INCARNATE))
      bonuses.push(ModifierKind.ENFEEBLE_INCARNATE);
  } else {
    if (code === 'ruinmaw') bonuses.push(ModifierKind.EMPOWER_RUINMAW);
    if (modifiers && bonus_deck(modifiers, ModifierKind.EMPOWER_INCARNATE))
      bonuses.push(ModifierKind.EMPOWER_INCARNATE);
    if (is_game_enabled(campaign, CRIMSON_SCALES)) {
      bonuses.push(ModifierKind.OAKS_GIFT_ROLLING);
      bonuses.push(ModifierKind.OAKS_GIFT_2X);
      campaign.state.unlocked_sanctuary_modifiers?.forEach((kind) => bonuses.push(kind));
    }
  }
  return bonuses;
}

export function bonus_deck(modifiers: AttackModifiers, kind: ModifierKind) {
  switch (kind) {
    case ModifierKind.MONSTER_CURSE:
      return modifiers.monster_curses;
    case ModifierKind.PLAYER_CURSE:
      return modifiers.player_curses;
    case ModifierKind.BLESS:
      return modifiers.blesses;
    case ModifierKind.MINUS_ONE:
      return modifiers.minus_ones;
    case ModifierKind.OAKS_GIFT_ROLLING:
      return modifiers.oaks_gift_rolling;
    case ModifierKind.OAKS_GIFT_2X:
      return modifiers.oaks_gift_2x;
    case ModifierKind.HONORED_1:
    case ModifierKind.HONORED_2:
    case ModifierKind.HONORED_3:
    case ModifierKind.HONORED_4:
    case ModifierKind.EMPOWER_INCARNATE:
    case ModifierKind.ENFEEBLE_INCARNATE:
      return modifiers.bonus_decks?.[kind];
    case ModifierKind.EMPOWER_RUINMAW:
      return modifiers.empower['ruinmaw'];
  }
  return null;
}

export interface HasSectionIndex {
  section_index: number;
}

export function is_section_locked(playing_state: PlayingState, section: HasSectionIndex) {
  return (
    playing_state.round_state.section_status?.[section?.section_index] ===
    ScenarioSectionStatus.LOCKED
  );
}

export function is_section_revealed(
  playing_state: PlayingState,
  section: HasSectionIndex
) {
  return (
    playing_state.round_state.section_status?.[section?.section_index] ===
    ScenarioSectionStatus.REVEALED
  );
}

export function is_section_visible(
  playing_state: PlayingState,
  section: HasSectionIndex
) {
  const section_status =
    playing_state.round_state.section_status?.[section?.section_index];
  return section_status == null || section_status === ScenarioSectionStatus.VISIBLE;
}

export function is_section_hidden(playing_state: PlayingState, section: HasSectionIndex) {
  if (!section) return false;
  if (!playing_state.round_state.section_status) return true;
  return !playing_state.round_state.section_status[section.section_index];
}

// A special rule spawn/summon can specify a comma separated list
// of tokens to spawn at. The count is how many times the special
// rule has been performed.
export function summon_target_arg(target: string, count: number) {
  if (!target) return '';
  const arr = target.split(',');
  if (arr.length <= 1) return arr[0];
  return arr[count % arr.length];
}

export function find_building(campaign: Campaign, building_id: number) {
  return campaign.frosthaven?.buildings?.find((b) => b.building_id === building_id);
}

export function is_building_unlocked(campaign: Campaign, building_id: number) {
  return Boolean(find_building(campaign, building_id));
}

export function is_building_at_least_level(
  campaign: Campaign,
  building_id: number,
  level: number
) {
  const building = find_building(campaign, building_id);
  return building && building.level >= level;
}

export function is_wrecked(building: BuildingState) {
  return building && building.status === BuildingStatus.WRECKED;
}

// Compare map_state.overlays for the sake of rendering.
export function compare_overlay(a: MapItem, b: MapItem) {
  return (
    map_item_kind_order[a.map_item_kind] - map_item_kind_order[b.map_item_kind] ||
    b.size - a.size ||
    a.id - b.id
  );
}

export function compare_monster(a: MapItem, b: MapItem, variant: number) {
  if (a.map_item_kind !== MapItemKind.MONSTER || b.map_item_kind !== MapItemKind.MONSTER)
    return 0;
  if (!a.variants || !b.variants) return 0;
  return a.variants[variant].localeCompare(b.variants[variant]);
}

// Returns true if the elements required by the ability are already consumed.
export function can_perform_consume(
  ability: Ability,
  elements: HashMap<number>,
  available?: boolean
) {
  if (!ability?.elements?.length || !elements) return false;
  const flag = available ? ElementState.AVAILABLE : ElementState.CONSUMED;
  const entries = Object.entries(elements).filter(([e, s]) => (s & flag) !== 0);
  if ((ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.IF_CONSUME_ONE_OF) {
    // TODO: This assumes an element never appears more than once in an ability
    // card.
    const consuming = new Set<string>(entries.map(([e, s]) => e));
    return ability.elements.some((e) => consuming.has(e));
  }
  if (ability.elements.length > entries.length) return false;
  const consuming = new Set<string>(entries.map(([e, s]) => e));
  consuming.add('any_element');
  return !ability.elements.some((e) => !consuming.has(e));
}

export function can_perform_conditions(ability: Ability, figure: FigureState): boolean {
  if (!figure?.conditions || !ability.conditions?.length) return false;
  return !ability.conditions.some((c) => !has_condition(figure.conditions, c));
}

export function can_perform_stat(ability: Ability, figure: FigureState): boolean {
  if (!figure?.conditions || ability.conditions?.length !== 1) return false;
  const state = figure.conditions[ability.conditions?.[0]];
  return state && state.count === ability.value;
}

export function is_friendly(ability: Ability) {
  return (
    ability.kind === AbilityKind.HEAL ||
    ability.kind === AbilityKind.PERSISTENT ||
    ability.conditions?.some((id) => condition_info[id]?.kind === ConditionKind.POSITIVE)
  );
}

export function empty_figure_group(
  info_id: number,
  info_kind: InfoKind,
  portrait_layout: FsImageLayout,
  image_info_id?: number
): FigureGroup {
  return {
    name: '',
    object_code: '',
    info_id,
    info_kind,
    monster_deck_id: -1,
    group_id: -1,
    image_info_id,
    standee_count: 4,
    free_standees: [],
    initiative: 100,
    second_init: 100,
    socket_id: '',
    base_health: [2],
    base_abilities_v2: [],
    abilities_v2: [],
    level: 1,
    figure_status: 0,
    figure_order: [],
    is_dead: false,
    ability_card_index: -1,
    modifier_code: '',
    portrait_layout,
  };
}

export function empty_figure(
  info_id: number,
  variant: string,
  standee_color?: string,
  image_info_id?: number
): FigureState {
  return {
    id: -1,
    change_id: -1,
    object_code: '',
    modifier_code: '',
    info_id,
    image_info_id,
    variant,
    health: 2,
    max_health: 2,
    standee_number: '1',
    standee_color: standee_color ?? 'figure-' + variant,
    conditions: {},
    q: 0,
    r: 1,
    prev_hexes: [],
    figure_status: 0,
    auto_status: 0,
  };
}

export function has_persist_condition(group: FigureGroup, condition_id: number) {
  return group?.base_abilities_v2?.some(
    (a) => is_persist(a) && a.conditions?.includes(condition_id)
  );
}
