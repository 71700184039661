// Abilities are used to describe:
// - base stats of figures
// - ability card actions (players and monsters)
// In each case, a simple array of Ability objects is used.
//
// Most abilities have 1 or more targets and should be consistently deducable
// from the array.
//
// Multiple abilities might depend on each other (e.g., use the same targets)
// and should be made apparent in the UI. Pseudo abilites are used to either
// draw a line between abilities, or combine abilities into a single line.
// Though, a front end can render them differently.
import type { HashMap } from './utility.js';

export type Action = string[];

export enum AoeHexKind {
  NONE = 0,
  SELF = 1,
  TARGET = 2,
  ALLY = 3,
  ENHANCE = 16,
}

export interface AoeHex {
  q: number;
  r: number;
  kind: AoeHexKind;
}

export interface Ability {
  kind: AbilityKind;
  value?: string | number; // The value of kind, depending on Target.EVAL
  value_elite?: string | number;
  flags?: number;
  conditions?: number[];
  conditions_elite?: number[]; // this should only be set for ATTACK or MOVE
  elements?: string[];
  text?: string; // TODO: combine with target_text. When set always display it.
  aoe_hexes?: AoeHex[];
  actions?: Action[]; // summon: [ info_id, target, 2p, 3p, 4p ]
  // abilities in the effects array never have an effects or nested field.
  effects?: Ability[]; // limited to range, pierce, push, pull, multi
  nested?: Ability[]; // other effects that modify the ability in some way
  delta?: string; // When combining, save the delta for display
  info_id?: number; // info_id of monster being granted actions or targeted
  enhancements?: number[]; // index to AbilityCardInfo.slots
  custom?: number[];
}

export interface AbilityCompat extends Ability {
  hexes?: number[];
}

// Remember to edit related code when changing this enum.
// Some of these should appear only as nested abilities.
export enum AbilityKind {
  NONE = 0,
  MOVE = 1,
  ATTACK = 2,
  CONDITION = 3,
  TARGET = 4,
  RANGE = 5,
  PIERCE = 6,
  PUSH_PULL = 7,
  HEAL = 8,
  DAMAGE = 9,
  LOOT = 10,
  SUMMON = 11,
  TRAP = 12,
  ELEMENT = 13,
  SPECIAL = 14,
  PERSISTENT = 15,
  TEXT = 16,
  TARGET_ONLY = 17, // specifies target details only, e.g., in consume
  IF = 18,
  CUSTOM = 19, // very specific actions
  CYCLE = 20, // Used by GH Prime Demon abilities only
  XP = 21,
  STAT = 22,
  KILL = 23,
  TRANSFER = 24,
  CARD = 25,
}

export enum AbilityFlag {
  KIND_MASK = 0xf,
  NONE = -1,
  // AbilityKind.MOVE
  MOVE = 0,
  SWIM = 1,
  JUMP = 2,
  SWIM_JUMP = 3,
  FLY = 4,
  TELEPORT = 5,
  // AbilityKind.ATTACK
  DEFAULT = 0, // ranged if base has range or card has range, otherwise melee
  MELEE = 1,
  RANGE = 2,
  // AbilityKind.CONDITION
  EFFECT = 0,
  IMMUNE = 1,
  PERSIST = 2,
  // AbilityKind.HEAL
  // AbilityKind.DAMAGE
  ATTACK_DAMAGE = 1, // for UI
  RETALIATE_DAMAGE = 2, // for UI, apply to current figure
  // AbilityKind.PUSH_PULL
  PUSH = 0,
  PULL = 1,
  // AbilityKind.LOOT
  // AbilityKind.SUMMON
  SUMMON = 0,
  SPAWN = 1,
  CHARACTER = 2,
  // AbilityKind.TRAP
  // AbilityKind.ELEMENT
  INFUSE = 0,
  CONSUME = 1,
  // AbilityKind.SPECIAL
  SPECIAL_1 = 0,
  SPECIAL_2 = 1,
  // AbilityKind.PERSISTENT, 0 for none
  ADVANTAGED_ATTACK = 1, // applied to a single attack
  ADVANTAGED = 2,
  ADVANTAGED_END_OF_ROUND = 3,
  ATTACKER_DISADVANTAGED = 4,
  ATTACKER_DISADVANTAGED_END_OF_ROUND = 5,
  SHIELD = 6,
  SHIELD_END_OF_ROUND = 7,
  RETALIATE = 8,
  RETALIATE_END_OF_ROUND = 9,
  RETALIATE_RANGE = 10,
  RETALIATE_RANGE_END_OF_ROUND = 11,
  // AbilityKind.RANGE
  // DEFAULT = 0, range attacks
  // MELEE = 1,  melee attacks in a range
  // AbilityKind.TARGET: use enum Target as kind
  // AbilityKind.PIERCE
  // AbilityKind.TEXT
  TEXT = 0,
  TEXT_GRID2 = 1,
  TEXT_HELP = 2,
  // AbilityKind.TARGET_ONLY
  // AbilityKind.IF, note that IF should never use EVAL
  IF_TEXT = 0,
  IF_TEXT2 = 1,
  IF_CONSUME = 2,
  IF_NORMAL = 3,
  IF_ELITE = 4,
  WHEN_TARGET_ADJACENT_TO_ALLIES = 5,
  ON_DEATH = 6,
  IF_PERFORM = 7, // also for any grant ability
  IF_STAT = 8,
  IF_ACTIVE = 9,
  IF_USE_SLOT = 10,
  IF_CONSUME_ONE_OF = 11,
  IF_HAVE_CONDITION = 12,
  IF_HAVE_STAT = 13,
  // AbilityKind.CUSTOM
  HATCH = 1,
  SWAP_VARIANTS = 2,
  CARD_IMAGE = 3, // uses hexes to store data
  // AbilityKind.CYCLE
  CYCLE_BEGIN = 0,
  CYCLE_END = 1,
  CYCLE_ADD = 2,
  // AbilityKind.XP
  // AbilityKind.KILL
  KILL = 0,
  DESTROY = 1,
  // AbilityKind.TRANSFER
  // AbilityKind.CARD
  // DEFAULT = 0,
  TOP = 1,
  BOTTOM = 2,
  // How to evalute the value in the ability.
  EVAL_MASK = 0xf0,
  EVAL_NONE = 0,
  EVAL_VALUE = 1 << 4, // On an ability card, add the base. On a stat card it is the base.
  EVAL_EXPR = 2 << 4,
  EVAL_AS_IS = 3 << 4, // Use the value as is.
  // Display as a delta. TODO: Which ability does it apply to for the sake of
  // auto? What if there is more than one matching? I'll assume it is always
  // obvious for now. i.e., the previous ability?
  EVAL_DELTA = 4 << 4,
  EVAL_TEXT = 5 << 4, // Don't know the value, just display the text
  EVAL_TARGET = 6 << 4, // performed as the target
  // When targeting a combination of factions. Default is enemies when no
  // faction explicitly specified.
  FACTION_MASK = 0xff0f00,
  SELF = 0x100,
  ALLIES = 0x200,
  ENEMIES = 0x400,
  FIGURES = 0x700,
  OBSTACLES = 0x800,
  OBJECTIVES = 0x10000,
  BOSSES = 0x20000,
  NORMAL = 0x40000,
  ELITE = 0x80000,
  SUMMONS = 0x100000,
  // Misc. flags that add text and have an effect to automate.
  MISC_MASK = 0xf000,
  ADJACENT_EMPTY_HEX = 0x1000,
  HEX_ADJACENT_TO_TARGET = 0x2000,
  ONE_WITH_ALL_ATTACKS = 0x3000,
  FOCUS_ON_FARTHEST = 0x4000,
  NON_TARGETED = 0x5000, // Don't need LOS for this target and affects invisible.
  EMPTY_HEX_IN_RANGE = 0x6000,
}

export enum Target {
  ONE = 0, // One target, adjacent or in range
  MULTI = 1,
  ALL_ADJACENT = 2,
  ALL_IN_RANGE = 3,
  ALL_ON_SAME_TILE = 4,
  ALL = 5,
  TEXT = 6,
  AOE_MELEE = 7,
  AOE_RANGE = 8,
  ALL_ADJACENT_TO_TARGET = 9,
  ONE_ADJACENT = 10,
  THE_TARGET = 11,
  ON_THE_MOVE = 12, // occupying or adjacent to each hex entered
  ALL_WITH_CONDITION = 13, // condition_id in ability.conditions
  // Cannot be higher than 15 because it fits KIND_MASK.
}

export const move_kinds = [
  AbilityFlag.MOVE,
  AbilityFlag.SWIM,
  AbilityFlag.JUMP,
  AbilityFlag.SWIM_JUMP,
  AbilityFlag.FLY,
  AbilityFlag.TELEPORT,
];

type AbilityKindInfo = {
  kind: AbilityKind;
  name: string;
  names?: string[];
  icons?: string[];
  text?: string[];
  column?: number[];
  layout?: string;
  default_target?: AbilityFlag;
};

export const _ability_kind_info: AbilityKindInfo[] = [
  { kind: AbilityKind.NONE, name: 'none' },
  {
    kind: AbilityKind.MOVE,
    name: 'move',
    icons: ['move', 'move', 'jump', 'jump', 'fly', 'teleport'],
    layout: 'stat',
  },
  {
    kind: AbilityKind.ATTACK,
    name: 'attack',
    layout: 'stat',
    default_target: AbilityFlag.ENEMIES,
  },
  {
    kind: AbilityKind.CONDITION,
    name: 'condition',
    column: [1, 2],
  },
  {
    kind: AbilityKind.TARGET,
    name: 'target',
    layout: 'stat',
  },
  {
    kind: AbilityKind.RANGE,
    name: 'range',
    layout: 'stat',
  },
  {
    kind: AbilityKind.PIERCE,
    name: 'pierce',
    layout: 'stat',
  },
  {
    kind: AbilityKind.PUSH_PULL,
    name: 'push_pull',
    icons: ['push', 'pull'],
    layout: 'stat',
    default_target: AbilityFlag.ENEMIES,
  },
  {
    kind: AbilityKind.HEAL,
    name: 'heal',
    layout: 'stat',
    default_target: AbilityFlag.SELF | AbilityFlag.ALLIES,
  },
  {
    kind: AbilityKind.DAMAGE,
    name: 'damage',
    layout: 'stat',
    default_target: AbilityFlag.ENEMIES,
  },
  {
    kind: AbilityKind.LOOT,
    name: 'loot',
    icons: ['loot'],
    layout: 'stat',
    default_target: AbilityFlag.SELF,
  },
  {
    kind: AbilityKind.SUMMON,
    name: 'summon',
    names: ['summon', 'spawn'],
    layout: 'text',
  },
  { kind: AbilityKind.TRAP, name: 'trap', layout: 'text' },
  { kind: AbilityKind.ELEMENT, name: 'element' },
  { kind: AbilityKind.SPECIAL, name: 'special', layout: 'text' },
  {
    kind: AbilityKind.PERSISTENT,
    name: 'persistent',
    layout: 'stat',
    names: [
      '',
      'advantaged',
      'advantaged',
      'advantaged',
      'attacker_disadvantaged',
      'attacker_disadvantaged',
      'shield',
      'shield',
      'retaliate',
      'retaliate',
      'range',
      'range',
    ],
    icons: [
      '',
      'advantaged',
      'advantaged',
      'advantaged',
      'attacker_disadvantaged',
      'attacker_disadvantaged',
      'shield',
      'shield_round',
      'retaliate',
      'retaliate_round',
      'range',
      'range',
    ],
    text: [
      '',
      'Advantage',
      'Advantage',
      'Advantage',
      'Attacker gains Disadvantage',
      'Attacker gains Disadvantage',
    ],
    column: [2, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
    default_target: AbilityFlag.SELF,
  },
  {
    kind: AbilityKind.TEXT,
    name: 'text',
    names: ['text', 'text', 'help'],
    column: [1, 2, 2],
  },
  { kind: AbilityKind.TARGET_ONLY, name: 'target_only' },
  {
    kind: AbilityKind.IF,
    name: 'if',
    names: [
      'if',
      'if',
      'if consume',
      'if vnormal',
      'if velite',
      'if tgt adj ally',
      'if',
      'if',
      'if stat',
      'if active',
      'if use slot',
      'if consume one of',
      'if have condition',
      'if have stat',
    ],
    column: [1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1],
  },
  { kind: AbilityKind.CUSTOM, name: 'custom', layout: 'text' },
  { kind: AbilityKind.CYCLE, name: 'cycle', column: [0, 0, 0] },
  { kind: AbilityKind.XP, name: 'xp', default_target: AbilityFlag.SELF },
  { kind: AbilityKind.STAT, name: 'stat', default_target: AbilityFlag.SELF },
  {
    kind: AbilityKind.KILL,
    name: 'kill',
    names: ['kill', 'destroy'],
    icons: ['skull', 'skull'],
    default_target: AbilityFlag.ENEMIES,
  },
  {
    kind: AbilityKind.TRANSFER,
    name: 'transfer',
    default_target: AbilityFlag.SUMMONS,
  },
  {
    kind: AbilityKind.CARD,
    name: 'card',
    default_target: AbilityFlag.SELF,
  },
];

interface HasAbilityKind {
  kind: AbilityKind;
}

export function ability_icon(ability: Ability): string {
  if (!ability || !_ability_kind_info[ability.kind]) return null;
  const info = _ability_kind_info[ability.kind];
  const kind = ability.flags & AbilityFlag.KIND_MASK;
  return info.icons ? info.icons[kind] : info.name;
}

export function ability_name(ability: Ability): string {
  if (!ability || !_ability_kind_info[ability.kind]) return null;
  const info = _ability_kind_info[ability.kind];
  const kind = ability.flags & AbilityFlag.KIND_MASK;
  return info.names ? info.names[kind] : info.icons ? info.icons[kind] : info.name;
}

export function ability_delta(ability: Ability): string {
  return ability.delta;
}

export function persistent_name(kind: AbilityFlag): string {
  return _ability_kind_info[AbilityKind.PERSISTENT].names[kind] ?? '';
}

export function ability_layout(ability: Ability): string {
  if (_ability_kind_info[ability.kind].layout)
    return _ability_kind_info[ability.kind].layout;
  return ability_name(ability);
}

export function ability_column(ability: Ability): number {
  if (!ability || !_ability_kind_info[ability.kind]) return 1;
  const info = _ability_kind_info[ability.kind];
  if (!info.column) return 1;
  const kind = ability.flags & AbilityFlag.KIND_MASK;
  return info.column[kind];
}

function target_pattern(kind: Target): String {
  // Cannot be higher than 15 because it fits KIND_MASK.
  switch (kind) {
    case Target.ONE:
      return '{self}one {factions}';
    case Target.ONE_ADJACENT:
      return '{self}one adjacent {factions}';
    case Target.ALL:
      return '{self}all {factions}';
    case Target.ALL_ADJACENT:
      return '{self}all adjacent {factions}';
    case Target.ALL_IN_RANGE:
      return '{self}all {factions}{range}';
    case Target.ALL_ON_SAME_TILE:
      return '{self}all {factions} on the same tile';
    case Target.ALL_ADJACENT_TO_TARGET:
      return '{self}all {factions} adjacent to the target';
    case Target.THE_TARGET:
      return 'the target';
    case Target.ALL_WITH_CONDITION:
      return '{self}all {factions} with {condition}';
    case Target.MULTI:
    case Target.TEXT:
    case Target.AOE_MELEE:
    case Target.AOE_RANGE:
    case Target.ON_THE_MOVE:
      return '';
  }
  return '';
}

export function target_description(
  target: Ability,
  range: Ability,
  dense?: boolean
): string {
  if (!target) return '';
  const tgt_kind = target.flags & AbilityFlag.KIND_MASK;
  if (tgt_kind === Target.THE_TARGET) return 'the target';
  if (tgt_kind === Target.TEXT) return target.text ?? '';
  const tgt_factions = target.flags & AbilityFlag.FACTION_MASK;
  if (tgt_factions === AbilityFlag.SELF) return 'self';
  const is_plural = is_target_plural(target);
  const join = is_plural ? ' and ' : ' or ';
  let self = tgt_factions & AbilityFlag.SELF ? 'self' + join : '';
  let factions = '';
  if (tgt_factions === AbilityFlag.FIGURES) {
    self = '';
    factions = 'figures';
  } else {
    const faction_names = is_plural ? faction_plural : faction_single;
    const names: string[] = [];
    for (let f = 1; f < 20; f++) {
      if (tgt_factions & (1 << (f + 8))) names.push(faction_names[f]);
    }
    if (names.length === 1) factions = names[0];
    else if (names.length === 2) factions = names[0] + join + names[1];
    else if (names.length > 2)
      factions = names.slice(0, -1).join(', ') + ',' + join + names[names.length - 1];
  }
  return target_pattern(tgt_kind)
    .replace('{condition}', String(target.conditions?.[0]))
    .replace('{self}', self)
    .replace('{factions}', factions)
    .replace(
      '{range}',
      range ? (dense ? '' : ' within Range ' + String(range.value)) : ' within range'
    );
}

const faction_single: string[] = [
  'self',
  'ally',
  'enemy',
  'obstacle',
  null,
  null,
  null,
  null,
  'objective',
  'boss',
  'normal',
  'elite',
];

const faction_plural: string[] = [
  'self',
  'allies',
  'enemies',
  'obstacles',
  null,
  null,
  null,
  null,
  'objectives',
  'bosses',
  'normal',
  'elite',
];

const target_text_map: HashMap<string> = {};

export function is_target_plural(target: Ability): boolean {
  const kind = target ? target.flags & AbilityFlag.KIND_MASK : Target.ONE;
  return ![Target.ONE, Target.ONE_ADJACENT, Target.THE_TARGET].includes(kind);
}

export function is_target_self_only(ability: Ability): boolean {
  return (ability.flags & AbilityFlag.FACTION_MASK) === AbilityFlag.SELF;
}

export function is_target_self(ability: Ability): boolean {
  return (ability.flags & AbilityFlag.SELF) !== 0;
}

export function is_target_allies(ability: Ability): boolean {
  return (ability.flags & AbilityFlag.ALLIES) !== 0;
}

export function is_target_enemies(ability: Ability): boolean {
  return (ability.flags & AbilityFlag.ENEMIES) !== 0;
}

export function is_target_all_with_cond(ability: Ability): boolean {
  return (
    is_target(ability) &&
    (ability.flags & AbilityFlag.KIND_MASK) === Target.ALL_WITH_CONDITION &&
    ability.conditions?.length === 1
  );
}

export function is_target_multi(ability: Ability): boolean {
  return is_target(ability) && (ability.flags & AbilityFlag.KIND_MASK) === Target.MULTI;
}

export function is_target_adjacent(ability: Ability): boolean {
  if (!is_target(ability)) return false;
  const kind = ability.flags & AbilityFlag.KIND_MASK;
  return kind === Target.ALL_ADJACENT || kind === Target.ONE_ADJACENT;
}

export function is_non_targeted(ability: Ability): boolean {
  return (
    is_damage(ability) ||
    (ability.flags & AbilityFlag.MISC_MASK) === AbilityFlag.NON_TARGETED
  );
}

// For condition effects that target all in range, target_text() merges
// the range into the text if it is the only effect.
export function is_combine_effects(ability: Ability): boolean {
  if (ability.effects?.length !== 2) return false;
  const target = ability.effects.find((a) => a.kind === AbilityKind.TARGET);
  const range = ability.effects.find((a) => a.kind === AbilityKind.RANGE);
  if (!target || !range) return false;
  const tgt_kind = target.flags & AbilityFlag.KIND_MASK;
  const rng_eval = range.flags & AbilityFlag.EVAL_MASK;
  return tgt_kind === Target.ALL_IN_RANGE && rng_eval === AbilityFlag.EVAL_AS_IS;
}

// What to display for condensed targets.
export function fh_target_text(ability: Ability): string {
  if (is_target_self_only(ability)) return null;
  if ((ability.flags & AbilityFlag.MISC_MASK) === AbilityFlag.ONE_WITH_ALL_ATTACKS)
    return null;
  const target = ability.effects?.find(is_target);
  const range = ability.effects?.find(is_range);
  return target ? target_description(target, range, true) : null;
}

export function target_text(ability: Ability): string {
  if (is_target_self_only(ability)) return ability.text ? '' : 'Self';
  if ((ability.flags & AbilityFlag.MISC_MASK) === AbilityFlag.ONE_WITH_ALL_ATTACKS)
    return 'Target one enemy with all attacks';
  const target = ability.effects?.find(is_target);
  if (!target) return null;
  const tgt_kind = target.flags & AbilityFlag.KIND_MASK;
  if (tgt_kind === Target.TEXT) return target.text;
  if (tgt_kind === Target.THE_TARGET) return 'The target';
  const range = ability.effects?.find(is_range);
  const text = target_description(target, range);
  const prefix = is_non_targeted(ability) ? 'Affect ' : 'Target ';
  return text ? prefix + text : '';
}

export function use_separator(abilities: Ability[], index: number): boolean {
  if (index <= 0) return false;
  const ability = abilities[index];
  if (ability.kind === AbilityKind.ELEMENT) return false;
  if (ability_column(ability) > 1) return false;
  return true;
}

export function is_eval_none(ability: Ability): boolean {
  return (ability.flags & AbilityFlag.EVAL_MASK) === AbilityFlag.EVAL_NONE;
}

export function is_eval_value(ability: Ability): boolean {
  return (ability.flags & AbilityFlag.EVAL_MASK) === AbilityFlag.EVAL_VALUE;
}

export function is_eval_text(ability: Ability): boolean {
  return (ability.flags & AbilityFlag.EVAL_MASK) === AbilityFlag.EVAL_TEXT;
}

export function is_eval_expr(ability: Ability): boolean {
  return (ability.flags & AbilityFlag.EVAL_MASK) === AbilityFlag.EVAL_EXPR;
}

export function is_eval_delta(ability: Ability) {
  return (ability.flags & AbilityFlag.EVAL_MASK) === AbilityFlag.EVAL_DELTA;
}

export function is_eval_as_is(ability: Ability) {
  return (ability.flags & AbilityFlag.EVAL_MASK) === AbilityFlag.EVAL_AS_IS;
}

export function is_eval_target(ability: Ability) {
  return (ability.flags & AbilityFlag.EVAL_MASK) === AbilityFlag.EVAL_TARGET;
}

export function is_melee_attack(ability: Ability): boolean {
  if (!ability) return false;
  return (
    ability.kind === AbilityKind.ATTACK &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.MELEE
  );
}

export function is_aoe_melee(ability: Ability): boolean {
  if (!ability) return false;
  return (
    ability.kind === AbilityKind.TARGET &&
    (ability.flags & AbilityFlag.KIND_MASK) === Target.AOE_MELEE
  );
}

export function is_aoe(ability: Ability): boolean {
  if (!ability) return false;
  const kind = ability.flags & AbilityFlag.KIND_MASK;
  return (
    ability.kind === AbilityKind.TARGET &&
    (kind === Target.AOE_MELEE || kind === Target.AOE_RANGE)
  );
}

export function is_if(ability: Ability): boolean {
  if (!ability) return false;
  return ability.kind === AbilityKind.IF;
}

// Returns true when the effect should be displayed as a stat, i.e., with icon
// and variant values. Filters out a TARGET that is not MULTI, which is handled
// elsewhere in that case.
export function is_stat_effect(ability: Ability): boolean {
  if (!ability) return false;
  const kind = ability.flags & AbilityFlag.KIND_MASK;
  return ability.kind !== AbilityKind.TARGET || kind === Target.MULTI;
}

export function is_target(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.TARGET;
}

export function is_target_text(ability: Ability): boolean {
  return is_target(ability) && (ability.flags & AbilityFlag.KIND_MASK) >= Target.TEXT;
}

export function is_persistent(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.PERSISTENT;
}

export function is_persistent_stat(ability: Ability): boolean {
  return (
    is_persistent(ability) &&
    (ability.flags & AbilityFlag.KIND_MASK) >= AbilityFlag.SHIELD
  );
}

export function is_retaliate(ability: Ability): boolean {
  return (
    ability &&
    ability.kind === AbilityKind.PERSISTENT &&
    [AbilityFlag.RETALIATE, AbilityFlag.RETALIATE_END_OF_ROUND].includes(
      ability.flags & AbilityFlag.KIND_MASK
    )
  );
}

export function is_retaliate_range(ability: Ability): boolean {
  return (
    ability &&
    ability.kind === AbilityKind.PERSISTENT &&
    [AbilityFlag.RETALIATE_RANGE, AbilityFlag.RETALIATE_RANGE_END_OF_ROUND].includes(
      ability.flags & AbilityFlag.KIND_MASK
    )
  );
}

export function is_shield(ability: Ability): boolean {
  return (
    ability &&
    ability.kind === AbilityKind.PERSISTENT &&
    [AbilityFlag.SHIELD, AbilityFlag.SHIELD_END_OF_ROUND].includes(
      ability.flags & AbilityFlag.KIND_MASK
    )
  );
}

export function is_shield_eor(ability: Ability): boolean {
  return (
    ability &&
    ability.kind === AbilityKind.PERSISTENT &&
    AbilityFlag.SHIELD_END_OF_ROUND === (ability.flags & AbilityFlag.KIND_MASK)
  );
}

export function is_retaliate_eor(ability: Ability): boolean {
  return (
    ability &&
    ability.kind === AbilityKind.PERSISTENT &&
    AbilityFlag.RETALIATE_END_OF_ROUND === (ability.flags & AbilityFlag.KIND_MASK)
  );
}

export function is_immune(ability: Ability): boolean {
  return (
    ability &&
    ability.kind === AbilityKind.CONDITION &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.IMMUNE
  );
}

export function is_persist(ability: Ability): boolean {
  return (
    ability &&
    ability.kind === AbilityKind.CONDITION &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.PERSIST
  );
}

export function is_condition_effect(ability: Ability): boolean {
  return (
    ability &&
    ability.kind === AbilityKind.CONDITION &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.EFFECT
  );
}

export function is_infuse(ability: Ability): boolean {
  if (!ability) return false;
  return (
    ability.kind === AbilityKind.ELEMENT &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.INFUSE
  );
}

export function is_consume(ability: Ability): boolean {
  if (!ability) return false;
  return (
    (ability.kind === AbilityKind.ELEMENT &&
      (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.CONSUME) ||
    (ability.kind === AbilityKind.IF &&
      [AbilityFlag.IF_CONSUME, AbilityFlag.IF_CONSUME_ONE_OF].includes(
        ability.flags & AbilityFlag.KIND_MASK
      ))
  );
}

export function is_if_consume(ability: Ability): boolean {
  if (!ability) return false;
  return (
    ability.kind === AbilityKind.IF &&
    [AbilityFlag.IF_CONSUME, AbilityFlag.IF_CONSUME_ONE_OF].includes(
      ability.flags & AbilityFlag.KIND_MASK
    )
  );
}

export function is_if_condition(ability: Ability): boolean {
  if (!ability) return false;
  return (
    ability.kind === AbilityKind.IF &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.IF_HAVE_CONDITION
  );
}

export function is_if_stat(ability: Ability): boolean {
  return (
    ability &&
    ability.kind === AbilityKind.IF &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.IF_HAVE_STAT
  );
}

export function is_if_normal(ability: Ability): boolean {
  if (!ability) return false;
  return (
    ability.kind === AbilityKind.IF &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.IF_NORMAL
  );
}

export function is_if_elite(ability: Ability): boolean {
  if (!ability) return false;
  return (
    ability.kind === AbilityKind.IF &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.IF_ELITE
  );
}

export function is_perform(ability: Ability) {
  return (
    ability &&
    ability.kind === AbilityKind.IF &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.IF_PERFORM
  );
}

export function is_if_active(ability: Ability): boolean {
  if (!ability) return false;
  return (
    ability.kind === AbilityKind.IF &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.IF_ACTIVE
  );
}

export function is_range(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.RANGE;
}

export function is_attack(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.ATTACK;
}

export function is_pierce(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.PIERCE;
}

export function is_text(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.TEXT;
}

export function is_push_pull(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.PUSH_PULL;
}

export function is_heal(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.HEAL;
}

export function is_damage(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.DAMAGE;
}

export function is_loot(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.LOOT;
}

export function is_trap_ability(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.TRAP;
}

export function is_stat_ability(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.STAT;
}

export function is_move(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.MOVE;
}

export function is_fly(ability: Ability) {
  return (
    ability &&
    ability.kind === AbilityKind.MOVE &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.FLY
  );
}

export function is_condition(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.CONDITION;
}

export function is_card_image(ability: Ability) {
  return (
    ability &&
    ability.kind === AbilityKind.CUSTOM &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.CARD_IMAGE
  );
}

export function is_kill(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.KILL;
}

export function is_transfer(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.TRANSFER;
}

export function is_xp(ability: Ability): boolean {
  return ability && ability.kind === AbilityKind.XP;
}

export function is_summon_ability(ability: HasAbilityKind) {
  return ability && ability.kind === AbilityKind.SUMMON;
}

export function is_spawn_ability(ability: Ability) {
  return (
    ability &&
    ability.kind === AbilityKind.SUMMON &&
    (ability.flags & AbilityFlag.KIND_MASK) === AbilityFlag.SPAWN
  );
}

export function is_matching_kind(
  ability: Ability,
  kind: AbilityKind,
  flags: AbilityFlag
) {
  const sub_kind = flags & AbilityFlag.KIND_MASK;
  const abi_kind = ability.flags & AbilityFlag.KIND_MASK;
  return ability && ability.kind === kind && abi_kind === sub_kind;
}

export function find_move_icon(abilities: Ability[]): string {
  const move = abilities?.find((a) => a.kind === AbilityKind.MOVE);
  return move ? ability_icon(move) : 'move';
}

export function move_icon(kind: AbilityFlag): string {
  const info = _ability_kind_info[AbilityKind.MOVE];
  return info.icons[kind];
}

export function set_eval(ability: Ability, val: AbilityFlag) {
  ability.flags ^= ability.flags & AbilityFlag.EVAL_MASK;
  ability.flags |= val & AbilityFlag.EVAL_MASK;
}

export function set_faction(ability: Ability, faction: AbilityFlag) {
  ability.flags ^= ability.flags & AbilityFlag.FACTION_MASK;
  ability.flags |= faction & AbilityFlag.FACTION_MASK;
}

export function factions_array(flags: number): number[] {
  let factions: number[] = [];
  let all_mask = AbilityFlag.FACTION_MASK;
  for (let i = 0; i < 32 && all_mask; i++) {
    const new_mask: AbilityFlag = (all_mask - 1) & all_mask;
    const one_mask = new_mask ^ all_mask;
    if (flags & one_mask) factions.push(one_mask);
    all_mask = new_mask;
  }
  return factions;
}

export function set_kind(ability: Ability, kind: AbilityFlag) {
  ability.flags ^= ability.flags & AbilityFlag.KIND_MASK;
  ability.flags |= kind & AbilityFlag.KIND_MASK;
}

export function set_misc(ability: Ability, misc: AbilityFlag) {
  ability.flags ^= ability.flags & AbilityFlag.MISC_MASK;
  ability.flags |= misc & AbilityFlag.MISC_MASK;
}

export function set_target(ability: Ability, kind: Target) {
  ability.flags ^= ability.flags & AbilityFlag.KIND_MASK;
  ability.flags |= kind;
}
