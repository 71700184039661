import { ModifierKind } from './info.js';
import { AbilityFlag } from './stats.js';
import type { HashMap } from './utility.js';

export enum Condition {
  NONE = 0,
  SHIELD,
  RETALIATE,
  ATTACKER_DISADVANTAGE,
  ADVANTAGE,
  POISON,
  POISON_COUNT,
  WOUND,
  WOUND_COUNT,
  IMMOBILIZE,
  DISARM /* 10 */,
  STUN,
  MUDDLE,
  BRITTLE,
  BANE,
  IMPAIR,
  CHILL,
  INFECT,
  INVISIBLE,
  STRENGTHEN,
  REGENERATE /* 20 */,
  WARD,
  SUMMON,
  BLESS,
  CURSE,
  MINUS_ONE,
  ANGRY,
  HATCHET,
  RED_GUARD,
  BOMBARD,
  CHAINGUARD /* 30 */,
  CHIEFTAIN,
  DEMOLITIONIST,
  RUPTURE,
  SATED,
  HIEROPHANT,
  DORMANT,
  EMPOWER_RUINMAW,
  VOID_ENERGY,
  SCRAP,
  TIME /* 40 */,
  SPIRIT,
  SPARK,
  SPEED,
  DEATHWALKER,
  EMPOWER_INCARNATE,
  ENFEEBLE_INCARNATE,
  ALLY,
  MONSTER_1,
  MONSTER_2,
  MONSTER_3 /* 50 */,
  MONSTER_4,
  DODGE,
  AID_TOKEN_A,
  AID_TOKEN_B,
  AID_TOKEN_C,
  AID_TOKEN_D,
  DAMAGE_COUNT,
  HEAL_COUNT,
  DAMAGE_ONE,
  HEAL_ONE /* 60 */,
  TROPHY,
  PRESSURE,
  RESONANCE,
  FOCUS_99,
  PUSH, // for immunities
  PULL,
  JUMP,
  END_OF_ROUND,
  FIRE_KNIGHT,
  NUMBER_TOKEN /* 70 */,
  MONSTER_M,
  INCARNATE_SPIRIT,
  AURA,
  FLY,
  MINDTHIEF,
  RETALIATE_RANGE,
  SCOUNDREL,
  MODE,
  FORM,
  GENERIC_COUNTER /* 80 */,
  LIGHTNING,
}

export enum ConditionKind {
  NONE = 0,
  NEGATIVE,
  POSITIVE,
  MODIFIER,
  DEFENSIVE,
  CHARACTER,
  STAT,
  TOGGLE_STAT,
}

export const condition_kind_names = [
  'None',
  'Negative',
  'Positive',
  'Modifier',
  'Defensive',
  'Token',
];

// The code assumes EXPIRE and HAS_COUNTER never exist together.
export enum ConditionFlag {
  NONE = 0,
  HAS_COUNTER = 1,
  CANCELS_HEAL = 2,
  HEALABLE = 4,
  EXPIRE_END_OF_FULL_TURN = 16,
  CHARACTER_TOKEN = 32,
  ATTACK_MODIFIER = 64,
  CAN_EDIT = 128, // show on group-edit and figure-edit
  CAN_VIEW = 256, // show on figure-unique
  CHARACTER_ONLY = 512, // show on figure-unique
  EXCLUDE_BULK = 1024, // don't show in bulk settings
  CLASS_ONLY = 2048, // Only show it when the class is in the party
}

export type ConditionState = {
  condition_id: number;
  name: string;
  expire_round: number;
  count?: number;
  round_count?: number;
  start_count?: number;
};

export type ConditionInfo = {
  condition_id: number;
  kind: ConditionKind;
  name: string;
  flags: number;
  game_ids: number[];
  class_ids?: number[];
  scenario_ids?: number[];
  max_count?: number;
  toggles?: string[];
  ability_kind?: AbilityFlag;
  modifier_kind?: ModifierKind;
};

export const condition_info: ConditionInfo[] = [
  {
    condition_id: Condition.NONE,
    kind: ConditionKind.NONE,
    name: 'none',
    flags: 0,
    game_ids: [],
  },
  {
    condition_id: Condition.SHIELD,
    kind: ConditionKind.DEFENSIVE,
    name: 'shield',
    flags: ConditionFlag.CAN_EDIT | ConditionFlag.CAN_VIEW | ConditionFlag.HAS_COUNTER,
    game_ids: [1, 4, 5, 6, 8089],
    ability_kind: AbilityFlag.SHIELD,
  },
  {
    condition_id: Condition.RETALIATE,
    kind: ConditionKind.DEFENSIVE,
    name: 'retaliate',
    flags: ConditionFlag.CAN_EDIT | ConditionFlag.CAN_VIEW | ConditionFlag.HAS_COUNTER,
    game_ids: [1, 5, 6, 8089],
    ability_kind: AbilityFlag.RETALIATE,
  },
  {
    condition_id: Condition.ATTACKER_DISADVANTAGE,
    kind: ConditionKind.DEFENSIVE,
    name: 'attacker_disadvantaged',
    flags: ConditionFlag.CAN_VIEW,
    game_ids: [1, 4, 5, 6],
    ability_kind: AbilityFlag.ATTACKER_DISADVANTAGED,
  },
  {
    condition_id: Condition.ADVANTAGE,
    kind: ConditionKind.DEFENSIVE,
    name: 'advantaged',
    flags: ConditionFlag.CAN_VIEW,
    game_ids: [1, 4, 5, 6],
    ability_kind: AbilityFlag.ADVANTAGED,
  },
  {
    condition_id: Condition.POISON,
    kind: ConditionKind.NEGATIVE,
    name: 'poison',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CANCELS_HEAL |
      ConditionFlag.HEALABLE,
    game_ids: [1, 4, 5, 6, 8089],
  },
  {
    condition_id: Condition.POISON_COUNT,
    kind: ConditionKind.NEGATIVE,
    name: 'poison_count',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CANCELS_HEAL |
      ConditionFlag.HEALABLE |
      ConditionFlag.HAS_COUNTER,
    max_count: 4,
    game_ids: [],
    class_ids: [320], // mirefoot
  },
  {
    condition_id: Condition.WOUND,
    kind: ConditionKind.NEGATIVE,
    name: 'wound',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT | ConditionFlag.HEALABLE,
    game_ids: [1, 4, 5, 6, 8089],
  },
  {
    condition_id: Condition.WOUND_COUNT,
    kind: ConditionKind.NEGATIVE,
    name: 'wound_count',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.HEALABLE |
      ConditionFlag.HAS_COUNTER,
    max_count: 2,
    game_ids: [],
    class_ids: [90, 320], // bladeswarm, mirefoot
  },
  {
    condition_id: Condition.IMMOBILIZE,
    kind: ConditionKind.NEGATIVE,
    name: 'immobilize',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.EXPIRE_END_OF_FULL_TURN,
    game_ids: [1, 4, 5, 6, 8089],
  },
  {
    condition_id: Condition.DISARM,
    kind: ConditionKind.NEGATIVE,
    name: 'disarm',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.EXPIRE_END_OF_FULL_TURN,
    game_ids: [1, 4, 5, 6, 8089],
  },
  {
    condition_id: Condition.STUN,
    kind: ConditionKind.NEGATIVE,
    name: 'stun',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.EXPIRE_END_OF_FULL_TURN,
    game_ids: [1, 4, 5, 6],
  },
  {
    condition_id: Condition.MUDDLE,
    kind: ConditionKind.NEGATIVE,
    name: 'muddle',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.EXPIRE_END_OF_FULL_TURN,
    game_ids: [1, 4, 5, 6, 8089],
  },
  {
    condition_id: Condition.BRITTLE,
    kind: ConditionKind.NEGATIVE,
    name: 'brittle',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT | ConditionFlag.HEALABLE,
    game_ids: [5],
  },
  {
    condition_id: Condition.BANE,
    kind: ConditionKind.NEGATIVE,
    name: 'bane',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.HEALABLE |
      ConditionFlag.EXPIRE_END_OF_FULL_TURN,
    game_ids: [5],
  },
  {
    condition_id: Condition.IMPAIR,
    kind: ConditionKind.NEGATIVE,
    name: 'impair',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.EXPIRE_END_OF_FULL_TURN,
    game_ids: [5],
  },
  {
    condition_id: Condition.CHILL,
    kind: ConditionKind.NEGATIVE,
    name: 'chill',
    // TODO: this is specially handled to reduce the counter each turn. Should
    // it be generalized?
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT | ConditionFlag.HAS_COUNTER,
    max_count: 9,
    game_ids: [6],
  },
  {
    condition_id: Condition.INFECT,
    kind: ConditionKind.NEGATIVE,
    name: 'infect',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT | ConditionFlag.HEALABLE,
    game_ids: [6],
  },
  {
    condition_id: Condition.INVISIBLE,
    kind: ConditionKind.POSITIVE,
    name: 'invisible',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.EXPIRE_END_OF_FULL_TURN,
    game_ids: [1, 4, 5, 6, 8089],
  },
  {
    condition_id: Condition.STRENGTHEN,
    kind: ConditionKind.POSITIVE,
    name: 'strengthen',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.EXPIRE_END_OF_FULL_TURN,
    game_ids: [1, 4, 5, 6, 8089],
  },
  {
    condition_id: Condition.REGENERATE,
    kind: ConditionKind.POSITIVE,
    name: 'regenerate',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [5, 6],
  },
  {
    condition_id: Condition.WARD,
    kind: ConditionKind.POSITIVE,
    name: 'ward',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [5, 6],
  },
  {
    condition_id: Condition.SUMMON,
    kind: ConditionKind.CHARACTER,
    name: 'summon',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [1, 4, 5, 6],
  },
  {
    condition_id: Condition.BLESS,
    kind: ConditionKind.POSITIVE,
    name: 'bless',
    flags:
      ConditionFlag.CAN_EDIT | ConditionFlag.HAS_COUNTER | ConditionFlag.ATTACK_MODIFIER,
    max_count: 10,
    game_ids: [1, 4, 5, 6],
    modifier_kind: ModifierKind.BLESS,
  },
  {
    condition_id: Condition.CURSE,
    kind: ConditionKind.NEGATIVE,
    name: 'curse',
    flags:
      ConditionFlag.CAN_EDIT | ConditionFlag.HAS_COUNTER | ConditionFlag.ATTACK_MODIFIER,
    max_count: 10,
    game_ids: [1, 4, 5, 6],
    modifier_kind: ModifierKind.MONSTER_CURSE,
  },
  {
    condition_id: Condition.MINUS_ONE,
    kind: ConditionKind.MODIFIER,
    name: 'minus_one',
    flags: ConditionFlag.HAS_COUNTER | ConditionFlag.ATTACK_MODIFIER,
    max_count: 15,
    game_ids: [1, 4, 5, 6],
    modifier_kind: ModifierKind.MINUS_ONE,
  },
  {
    condition_id: Condition.ANGRY,
    kind: ConditionKind.CHARACTER,
    name: 'angry',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [85],
  },
  {
    condition_id: Condition.HATCHET,
    kind: ConditionKind.CHARACTER,
    name: 'hatchet',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [92],
  },
  {
    condition_id: Condition.RED_GUARD,
    kind: ConditionKind.CHARACTER,
    name: 'red-guard',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [93],
  },
  {
    condition_id: Condition.BOMBARD,
    kind: ConditionKind.CHARACTER,
    name: 'bombard',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [312],
  },
  {
    condition_id: Condition.CHAINGUARD,
    kind: ConditionKind.CHARACTER,
    name: 'chainguard',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [314],
  },
  {
    condition_id: Condition.CHIEFTAIN,
    kind: ConditionKind.CHARACTER,
    name: 'chieftain',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [315],
  },
  {
    condition_id: Condition.DEMOLITIONIST,
    kind: ConditionKind.CHARACTER,
    name: 'demolitionist',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [91],
  },
  {
    condition_id: Condition.RUPTURE,
    kind: ConditionKind.NEGATIVE,
    name: 'rupture',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT | ConditionFlag.HEALABLE,
    game_ids: [6],
  },
  {
    condition_id: Condition.SATED,
    kind: ConditionKind.POSITIVE,
    name: 'sated',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_ONLY |
      ConditionFlag.EXPIRE_END_OF_FULL_TURN |
      ConditionFlag.EXCLUDE_BULK |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [321], // ruinmaw
  },
  {
    condition_id: Condition.HIEROPHANT,
    kind: ConditionKind.CHARACTER,
    name: 'hierophant',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [317],
  },
  {
    condition_id: Condition.DORMANT,
    kind: ConditionKind.CHARACTER,
    name: 'dormant',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [],
  },
  {
    condition_id: Condition.EMPOWER_RUINMAW,
    kind: ConditionKind.POSITIVE,
    name: 'empower',
    flags:
      ConditionFlag.HAS_COUNTER |
      ConditionFlag.ATTACK_MODIFIER |
      ConditionFlag.EXCLUDE_BULK,
    max_count: 12,
    game_ids: [],
    modifier_kind: ModifierKind.EMPOWER_RUINMAW,
  },
  {
    condition_id: Condition.VOID_ENERGY,
    kind: ConditionKind.STAT,
    name: 'void_energy',
    flags: ConditionFlag.HAS_COUNTER | ConditionFlag.CLASS_ONLY,
    max_count: 3,
    game_ids: [],
    class_ids: [318],
  },
  {
    condition_id: Condition.SCRAP,
    kind: ConditionKind.STAT,
    name: 'scrap',
    flags: ConditionFlag.HAS_COUNTER | ConditionFlag.CLASS_ONLY,
    max_count: 5,
    game_ids: [],
    class_ids: [311],
  },
  {
    condition_id: Condition.TIME,
    kind: ConditionKind.STAT,
    name: 'hourglass',
    flags: ConditionFlag.HAS_COUNTER | ConditionFlag.CLASS_ONLY,
    max_count: 5,
    game_ids: [],
    class_ids: [96],
  },
  {
    condition_id: Condition.SPIRIT,
    kind: ConditionKind.CHARACTER,
    name: 'spirit-caller',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [322],
  },
  {
    condition_id: Condition.SPARK,
    kind: ConditionKind.STAT,
    name: 'spark',
    flags: ConditionFlag.HAS_COUNTER | ConditionFlag.CLASS_ONLY,
    max_count: 5,
    game_ids: [],
    class_ids: [1512],
  },
  {
    condition_id: Condition.SPEED,
    kind: ConditionKind.TOGGLE_STAT,
    name: 'speed',
    toggles: ['fast', 'slow'],
    flags: ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [96],
  },
  {
    condition_id: Condition.DEATHWALKER,
    kind: ConditionKind.CHARACTER,
    name: 'deathwalker',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [98],
  },
  {
    condition_id: Condition.EMPOWER_INCARNATE,
    kind: ConditionKind.POSITIVE,
    name: 'empower',
    flags:
      ConditionFlag.HAS_COUNTER |
      ConditionFlag.ATTACK_MODIFIER |
      ConditionFlag.EXCLUDE_BULK,
    max_count: 10,
    game_ids: [],
    modifier_kind: ModifierKind.EMPOWER_INCARNATE,
  },
  {
    condition_id: Condition.ENFEEBLE_INCARNATE,
    kind: ConditionKind.NEGATIVE,
    name: 'enfeeble',
    flags:
      ConditionFlag.HAS_COUNTER |
      ConditionFlag.ATTACK_MODIFIER |
      ConditionFlag.EXCLUDE_BULK,
    max_count: 10,
    game_ids: [],
    modifier_kind: ModifierKind.ENFEEBLE_INCARNATE,
  },
  {
    condition_id: Condition.ALLY,
    kind: ConditionKind.CHARACTER,
    name: 'ally',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [5],
  },
  {
    condition_id: Condition.MONSTER_1,
    kind: ConditionKind.CHARACTER,
    name: 'monster_1',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [],
  },
  {
    condition_id: Condition.MONSTER_2,
    kind: ConditionKind.CHARACTER,
    name: 'monster_2',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [],
  },
  {
    condition_id: Condition.MONSTER_3,
    kind: ConditionKind.CHARACTER,
    name: 'monster_3',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [],
  },
  {
    condition_id: Condition.MONSTER_4,
    kind: ConditionKind.CHARACTER,
    name: 'monster_4',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [],
  },
  {
    condition_id: Condition.DODGE,
    kind: ConditionKind.POSITIVE,
    name: 'dodge',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [],
    class_ids: [1512],
  },
  {
    condition_id: Condition.AID_TOKEN_A,
    kind: ConditionKind.CHARACTER,
    name: 'aid_token_a',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [],
  },
  {
    condition_id: Condition.AID_TOKEN_B,
    kind: ConditionKind.CHARACTER,
    name: 'aid_token_b',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [],
  },
  {
    condition_id: Condition.AID_TOKEN_C,
    kind: ConditionKind.CHARACTER,
    name: 'aid_token_c',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [],
  },
  {
    condition_id: Condition.AID_TOKEN_D,
    kind: ConditionKind.CHARACTER,
    name: 'aid_token_d',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [],
  },
  {
    condition_id: Condition.DAMAGE_COUNT,
    kind: ConditionKind.NEGATIVE,
    name: 'damage_count',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.HAS_COUNTER |
      ConditionFlag.EXCLUDE_BULK,
    max_count: 4,
    game_ids: [],
  },
  {
    condition_id: Condition.HEAL_COUNT,
    kind: ConditionKind.POSITIVE,
    name: 'heal_count',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.HAS_COUNTER |
      ConditionFlag.EXCLUDE_BULK,
    max_count: 4,
    game_ids: [],
  },
  {
    condition_id: Condition.DAMAGE_ONE,
    kind: ConditionKind.NEGATIVE,
    name: 'damage_one',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT | ConditionFlag.EXCLUDE_BULK,
    game_ids: [],
    class_ids: [83],
  },
  {
    condition_id: Condition.HEAL_ONE,
    kind: ConditionKind.POSITIVE,
    name: 'heal_one',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT | ConditionFlag.EXCLUDE_BULK,
    game_ids: [1618],
  },
  {
    condition_id: Condition.TROPHY,
    kind: ConditionKind.STAT,
    name: 'trophy',
    flags: ConditionFlag.HAS_COUNTER | ConditionFlag.CLASS_ONLY,
    max_count: 5,
    game_ids: [],
    class_ids: [1617],
  },
  {
    condition_id: Condition.PRESSURE,
    kind: ConditionKind.TOGGLE_STAT,
    name: 'pressure',
    toggles: ['pressure-low', 'pressure-regular', 'pressure-high', 'pressure-over'],
    flags: ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [1621],
  },
  {
    condition_id: Condition.RESONANCE,
    kind: ConditionKind.STAT,
    name: 'resonance',
    flags: ConditionFlag.HAS_COUNTER | ConditionFlag.CLASS_ONLY,
    max_count: 5,
    game_ids: [],
    class_ids: [1624],
  },
  {
    condition_id: Condition.FOCUS_99,
    kind: ConditionKind.CHARACTER,
    name: 'focus_99',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [],
  },
  {
    condition_id: Condition.PUSH,
    kind: ConditionKind.CHARACTER,
    name: 'push',
    flags: 0,
    game_ids: [],
  },
  {
    condition_id: Condition.PULL,
    kind: ConditionKind.CHARACTER,
    name: 'pull',
    flags: 0,
    game_ids: [],
  },
  {
    condition_id: Condition.JUMP,
    kind: ConditionKind.CHARACTER,
    name: 'jump',
    flags: 0,
    game_ids: [],
  },
  {
    condition_id: Condition.END_OF_ROUND,
    kind: ConditionKind.CHARACTER,
    name: 'round_bonus',
    flags: 0,
    game_ids: [],
  },
  {
    condition_id: Condition.FIRE_KNIGHT,
    kind: ConditionKind.CHARACTER,
    name: 'fire-knight',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [316],
  },
  {
    condition_id: Condition.NUMBER_TOKEN,
    kind: ConditionKind.CHARACTER,
    name: 'number_token',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [],
    scenario_ids: [1817],
  },
  {
    condition_id: Condition.MONSTER_M,
    kind: ConditionKind.CHARACTER,
    name: 'monster',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT,
    game_ids: [],
  },
  {
    condition_id: Condition.INCARNATE_SPIRIT,
    kind: ConditionKind.TOGGLE_STAT,
    name: 'spirit',
    toggles: ['reaver', 'ritualist', 'conquerer'],
    flags: ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [1514],
  },
  {
    condition_id: Condition.AURA,
    kind: ConditionKind.CHARACTER,
    name: 'aura',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT | ConditionFlag.HAS_COUNTER,
    max_count: 4,
    game_ids: [],
  },
  {
    condition_id: Condition.FLY,
    kind: ConditionKind.CHARACTER,
    name: 'fly',
    flags: ConditionFlag.CAN_VIEW,
    game_ids: [],
  },
  {
    condition_id: Condition.MINDTHIEF,
    kind: ConditionKind.CHARACTER,
    name: 'mindthief',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [74],
  },
  {
    condition_id: Condition.RETALIATE_RANGE,
    kind: ConditionKind.DEFENSIVE,
    name: 'retaliate_range',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.HAS_COUNTER,
    game_ids: [1, 5, 6],
    ability_kind: AbilityFlag.RETALIATE_RANGE,
  },
  {
    condition_id: Condition.SCOUNDREL,
    kind: ConditionKind.CHARACTER,
    name: 'scoundrel',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [75],
  },
  {
    condition_id: Condition.MODE,
    kind: ConditionKind.CHARACTER,
    name: 'mode',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_ONLY |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [
      1619, 2522, 2523, 2524, 2525, 2526, 2527, 2528, 2529, 2530, 2531, 2532, 2533, 2534,
      8023,
    ],
  },
  {
    condition_id: Condition.FORM,
    kind: ConditionKind.TOGGLE_STAT,
    name: 'form',
    toggles: ['gmelee', 'granged'],
    flags: ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [100],
  },
  {
    condition_id: Condition.GENERIC_COUNTER,
    kind: ConditionKind.STAT,
    name: 'generic_counter',
    flags: ConditionFlag.CAN_VIEW | ConditionFlag.CAN_EDIT | ConditionFlag.HAS_COUNTER,
    max_count: 12,
    game_ids: [],
  },
  {
    condition_id: Condition.LIGHTNING,
    kind: ConditionKind.CHARACTER,
    name: 'lightning',
    flags:
      ConditionFlag.CAN_VIEW |
      ConditionFlag.CAN_EDIT |
      ConditionFlag.CHARACTER_TOKEN |
      ConditionFlag.CLASS_ONLY,
    game_ids: [],
    class_ids: [83],
  },
];

export const condition_info_map: Map<string, ConditionInfo> = new Map(
  condition_info.map((info) => [info.name, info])
);

// The order to display conditions
export const condition_order: number[] = [
  // Stats
  Condition.VOID_ENERGY,
  Condition.SCRAP,
  Condition.TIME,
  Condition.SPARK,
  Condition.SPEED,
  Condition.TROPHY,
  Condition.PRESSURE,
  Condition.RESONANCE,
  Condition.FORM,
  Condition.MODE,
  Condition.INCARNATE_SPIRIT,
  Condition.PUSH,
  Condition.PULL,
  Condition.JUMP,
  Condition.FLY,
  Condition.END_OF_ROUND,
  // Defensive
  Condition.SHIELD,
  Condition.RETALIATE,
  Condition.RETALIATE_RANGE,
  Condition.ATTACKER_DISADVANTAGE,
  Condition.ADVANTAGE,
  // Negative
  Condition.POISON,
  Condition.POISON_COUNT,
  Condition.WOUND,
  Condition.WOUND_COUNT,
  Condition.IMMOBILIZE,
  Condition.DISARM,
  Condition.STUN,
  Condition.MUDDLE,
  Condition.BRITTLE,
  Condition.BANE,
  Condition.IMPAIR,
  Condition.CHILL,
  Condition.INFECT,
  Condition.RUPTURE,
  Condition.DAMAGE_ONE,
  Condition.DAMAGE_COUNT,
  Condition.CURSE,
  Condition.ENFEEBLE_INCARNATE,
  // Positive
  Condition.INVISIBLE,
  Condition.STRENGTHEN,
  Condition.REGENERATE,
  Condition.WARD,
  Condition.DODGE,
  Condition.HEAL_ONE,
  Condition.HEAL_COUNT,
  Condition.BLESS,
  Condition.EMPOWER_RUINMAW,
  Condition.SATED,
  Condition.EMPOWER_INCARNATE,
  // Tokens
  Condition.NUMBER_TOKEN,
  Condition.GENERIC_COUNTER,
  Condition.SUMMON,
  Condition.ALLY,
  Condition.MONSTER_M,
  Condition.MONSTER_1,
  Condition.MONSTER_2,
  Condition.MONSTER_3,
  Condition.MONSTER_4,
  Condition.AID_TOKEN_A,
  Condition.AID_TOKEN_B,
  Condition.AID_TOKEN_C,
  Condition.AID_TOKEN_D,
  Condition.DORMANT,
  Condition.FOCUS_99,
  Condition.AURA,
  Condition.MINDTHIEF,
  Condition.SCOUNDREL,
  Condition.ANGRY,
  Condition.LIGHTNING,
  Condition.HATCHET,
  Condition.RED_GUARD,
  Condition.DEMOLITIONIST,
  Condition.BOMBARD,
  Condition.CHAINGUARD,
  Condition.CHIEFTAIN,
  Condition.FIRE_KNIGHT,
  Condition.HIEROPHANT,
  Condition.SPIRIT,
  Condition.DEATHWALKER,
  Condition.MINUS_ONE,
];

interface HasConditionId {
  condition_id: number;
}

export function get_condition_id(name: string) {
  const info = condition_info_map.get(name);
  return info?.condition_id ?? 0;
}

export function has_counter(state: HasConditionId) {
  const info = condition_info[state.condition_id];
  return (info.flags & ConditionFlag.HAS_COUNTER) !== 0;
}

export function has_toggle(state: HasConditionId) {
  const info = condition_info[state.condition_id];
  return info.kind === ConditionKind.TOGGLE_STAT;
}

export function can_expire(state: HasConditionId) {
  const info = condition_info[state.condition_id];
  return (info.flags & ConditionFlag.EXPIRE_END_OF_FULL_TURN) !== 0;
}

export function is_expired(state: ConditionState, round: number) {
  return can_expire(state) && round >= state.expire_round;
}

export function is_expiring(state: ConditionState, round: number) {
  return can_expire(state) && state.expire_round === round;
}

export function is_poison_like(state: HasConditionId) {
  const info = condition_info[state.condition_id];
  return (info.flags & ConditionFlag.CANCELS_HEAL) !== 0;
}

export function is_removed_by_heal(state: HasConditionId) {
  const info = condition_info[state.condition_id];
  return (info.flags & ConditionFlag.HEALABLE) !== 0;
}

export function is_attacker_disadvantaged(states: HashMap<ConditionState>) {
  return !!states[Condition.ATTACKER_DISADVANTAGE];
}

export function is_stunned(states: HashMap<ConditionState>) {
  return !!states[Condition.STUN];
}

export function is_disarmed(states: HashMap<ConditionState>) {
  return !!states[Condition.DISARM];
}

export function is_immobilized(states: HashMap<ConditionState>) {
  return !!states[Condition.IMMOBILIZE];
}

export function is_muddled(states: HashMap<ConditionState>) {
  return !!states[Condition.MUDDLE];
}

export function is_invisible(states: HashMap<ConditionState>) {
  return !!states[Condition.INVISIBLE];
}

export function is_dormant(states: HashMap<ConditionState>) {
  return !!states[Condition.DORMANT] || !!states[Condition.MODE];
}

export function is_spirit(states: HashMap<ConditionState>) {
  return !!states[Condition.SPIRIT];
}

export function is_summoned(states: HashMap<ConditionState>) {
  return !!states[Condition.SUMMON];
}

export function is_focus_99(states: HashMap<ConditionState>) {
  return !!states[Condition.FOCUS_99];
}

export function is_editable(info: ConditionInfo) {
  return (info.flags & ConditionFlag.CAN_EDIT) !== 0;
}

export function is_editable_cond(id: number) {
  return is_editable(condition_info[id]);
}

export function is_viewable_cond(id: number) {
  const info = condition_info[id];
  return (info.flags & ConditionFlag.CAN_VIEW) !== 0;
}

export function is_viewable(info: ConditionInfo) {
  return (info.flags & ConditionFlag.CAN_VIEW) !== 0;
}

export function is_attack_modifier(id: HasConditionId) {
  const info = condition_info[id.condition_id];
  return (info.flags & ConditionFlag.ATTACK_MODIFIER) !== 0;
}

export function condition_modifier_kind(id: HasConditionId, code: string) {
  const info = condition_info[id.condition_id];
  if (!info.modifier_kind) return ModifierKind.NONE;
  const kind = info.modifier_kind;
  if (kind === ModifierKind.MONSTER_CURSE && code !== 'monster')
    return ModifierKind.PLAYER_CURSE;
  return kind;
}

export function is_character_token(id: HasConditionId) {
  const info = condition_info[id.condition_id];
  return (info.flags & ConditionFlag.CHARACTER_TOKEN) !== 0;
}

export function is_class_only(info: ConditionInfo) {
  return (info.flags & ConditionFlag.CLASS_ONLY) !== 0;
}

export function has_condition(states: HashMap<ConditionState>, condition_id: number) {
  return !!states[condition_id];
}

export function condition_count(states: HashMap<ConditionState>, condition_id: number) {
  const state = states[condition_id];
  return state ? state.count : 0;
}

export function ordered_conditions(states: HashMap<ConditionState>): string[] {
  return Object.values(states)
    .sort((a, b) => a.condition_id - b.condition_id)
    .map((s) => s.name);
}

export function is_persistent_condition(state: ConditionState) {
  return !can_expire(state);
}

function info(state: ConditionState) {
  return condition_info[state.condition_id];
}

export function viewable_conditions(states: HashMap<ConditionState>): ConditionState[] {
  return condition_order
    .filter((id) => is_viewable_cond(id) && !!states[id])
    .map((id) => states[id]);
}

export function show_condition(info: ConditionInfo, info_id: number) {
  if (info.flags & ConditionFlag.CHARACTER_ONLY) {
    return info.class_ids.includes(info_id);
  }
  return true;
}

export function is_class_stat(info: ConditionInfo, info_id: number, can_edit?: boolean) {
  if (info.kind !== ConditionKind.STAT) return false;
  if (info.class_ids && info.class_ids.includes(info_id)) return true;
  // If the stat can be enabled in GameSettings, show it for all.
  return Boolean(can_edit);
}

export function is_class_toggle(info: ConditionInfo, info_id: number) {
  return info.kind === ConditionKind.TOGGLE_STAT && info.class_ids?.includes(info_id);
}

export function toggle_icon(id: HasConditionId, count: number) {
  const info = condition_info[id.condition_id];
  return info?.toggles?.[count] ?? '';
}

export function is_negative(info: ConditionInfo) {
  return info.kind === ConditionKind.NEGATIVE;
}

export function is_positive(info: ConditionInfo) {
  return info.kind === ConditionKind.POSITIVE;
}

export function is_basic(info: ConditionInfo) {
  return (
    (info.kind === ConditionKind.NEGATIVE || info.kind === ConditionKind.POSITIVE) &&
    is_editable(info) &&
    (info.flags & ConditionFlag.EXCLUDE_BULK) === 0
  );
}

export function basic_conditions(class_id?: number): ConditionInfo[] {
  return condition_order
    .map((id) => condition_info[id])
    .filter(
      (info) =>
        (is_basic(info) || is_character_token(info)) &&
        (!info.class_ids || info.class_ids.includes(class_id))
    );
}

export function stat_conditions(class_id?: number): ConditionInfo[] {
  return condition_order
    .map((id) => condition_info[id])
    .filter((info) => is_class_stat(info, class_id));
}
